<template>
	<div class="OpenningDialog">
		<div class="content">
			<h2>批量打开环境</h2>
			<ul class="info">
				<li>
					<span>已选环境：</span>
					<span>{{ envList.length }}个</span>
				</li>
				<li>
					<span> 已打开：</span>
					<span>{{ successed }}个</span>
				</li>
				<li>
					<span>打开失败：</span>
					<span>{{ failed }}个</span>
				</li>
				<li class="openning" v-if="openningIndex < envList.length">
					<span><img width="18" height="18" src="@/assets/images/env/loading.svg" alt="" />&nbsp;正在打开：</span>
					<span>{{ openningId }}</span>
				</li>
				<li class="finished" v-if="time">
					<span><img width="18" height="18" src="@/assets/images/env/check.png" alt="" />&nbsp;执行完毕，{{ time }}秒后自动关闭</span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	components: {},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		envList: {
			type: Array,
			default: () => [],
		},
	},
	name: "OpenningDialog",
	data() {
		return {
			openningIndex: 0,
			timer: null,
			time: 0,
		};
	},
	watch: {
		"$store.state.curEnvStatusList": {
			handler(newVal) {
				this.openningIndex++;
				if (this.openningIndex == this.envList.length) {
					this.time = 5;
					// 显示倒计时，关闭弹窗
					this.timer = setInterval(() => {
						this.time--;
						if (this.time == 0) {
							clearInterval(this.timer);
							this.close();
						}
					}, 1000);
				}
				this.envList.forEach(item => {
					item.open_status = this.$store.state.curEnvStatusList[item.id];
				});
			},
			deep: true,
		},
	},
	methods: {
		close() {
			this.$emit("close", false);
		},
	},
	computed: {
		openningId() {
			if (this.envList[this.openningIndex]) {
				return this.envList[this.openningIndex].id;
			} else {
				return "-";
			}
		},
		successed() {
			return this.envList.filter(item => item.open_status === "running").length;
		},
		failed() {
			return this.envList.filter(item => item.open_status === "standby").length;
		},
	},
};
</script>
<style lang="less" scoped>
.OpenningDialog {
	position: fixed;
	inset: 0;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
	backdrop-filter: blur(1px);
	transition: all 0.3s;
	.content {
		width: 500px;
		background-color: #fff;
		border-radius: 6px;
		padding-bottom: 24px;
		h2 {
			font-size: 18px;
			font-weight: 600;
			text-align: center;
			margin: 0;
			padding: 16px;
			border-bottom: 1px solid #e8e8e8;
		}

		.info {
			padding: 24px;
			li {
				padding: 8px 16px;
				border-radius: 4px;
				font-family: PingFang SC;
				font-size: 16px;
				color: #333;
				display: flex;
				align-items: center;
				justify-content: space-between;
				& + li {
					margin-top: 12px;
				}
				&:nth-child(1) {
					background-color: rgba(76, 132, 255, 0.2);
				}
				&:nth-child(2) {
					background-color: rgba(82, 196, 26, 0.2);
				}
				&:nth-child(3) {
					background-color: rgba(255, 77, 80, 0.2);
				}
				&.openning {
					background-color: rgba(200, 200, 200, 0.2);
				}
				&.finished {
					padding-left: 0;
				}
			}
		}
	}
}
</style>

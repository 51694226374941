<template>
	<div class="EnvironmentList">
		<nav>
			<div class="cate" @click="curCategory = ''" :class="{ active: curCategory === '' }">
				<span>我的环境</span>
			</div>
			<div class="cate" @click="curCategory = 'env_common'" :class="{ active: curCategory === 'env_common' }">
				<span>常用环境</span>
			</div>
			<div class="cate" @click="curCategory = 'recent_open'" :class="{ active: curCategory === 'recent_open' }">
				<span>最近打开</span>
			</div>
			<div class="cate" @click="curCategory = 'urgent_renewal'" :class="{ active: curCategory === 'urgent_renewal' }">
				<span>急需付费</span>
			</div>
			<div class="cate" @click="curCategory = 'no_auth_env'" :class="{ active: curCategory === 'no_auth_env' }">
				<span>待授权</span>
			</div>
			<div class="cate" @click="curCategory = 'no_bind'" :class="{ active: curCategory === 'no_bind' }">
				<span>待绑定环境</span>
			</div>
		</nav>
		<main>
			<div class="header">
				<div class="query">
					<a-button type="primary" @click="$router.push('/manage/addenv')">新建环境</a-button>
					<a-button @click="batchOpen">批量打开环境</a-button>
					<a-button @click="batchClose()">批量关闭环境</a-button>
					<a-button @click="multiAddVisible = true">批量导入</a-button>
					<a-button @click="isShowEditPlatform = true">自定义平台管理</a-button>
					<a-button @click="show_tagmanage = true">环境标签管理</a-button>
					<a-button>批量操作</a-button>
				</div>
				<div class="filter">
					<a-button icon="filter">筛选</a-button>
				</div>
			</div>
			<a-table
				:row-selection="{
					selectedRowKeys: selectedRowKeys,
					onChange: onSelectChange,
				}"
				:loading="table_loading"
				:columns="columns"
				:data-source="tableData"
				:row-key="(r, i) => i.toString()"
				:scroll="{ x: 1200 }">
				<!-- 所属平台  -->
				<span slot="cell_platform" slot-scope="text, record">{{ text }}{{ record.site }}</span>
				<!-- 标签  -->
				<span slot="cell_tag" slot-scope="text">
					{{ text }}
				</span>
				<div slot="operaTitle" class="title_operate">
					<div class="title_operate_left">操作</div>
				</div>
				<div slot="cell_operate" slot-scope="text, record" class="content_operate">
					<div @click="go_bind(record)" v-if="!record.bind_status" class="go_bind_text">绑定</div>
					<div class="btns" v-else>
						<!-- 关闭  -->
						<template v-if="$store.state.curEnvStatusList[record.id.toString()] === 'running'">
							<button class="close" @click="batchClose(record.id)">
								<img width="18" height="18" src="@/assets/images/add_env/icon-chrome.png" alt="" />
								<span>关闭</span>
							</button>
							<img
								class="icon_bringToFront"
								width="16"
								height="16"
								src="@/assets/images/env/icon_computer.png"
								alt=""
								title="显示窗口"
								@click="bringToFront(record.id)" />
						</template>
						<!-- 打开  -->
						<button v-else class="open" @click="open(record)" :disabled="$store.state.curEnvStatusList[record.id.toString()] === 'loading'">
							<img width="18" height="18" src="@/assets/images/add_env/icon-chrome.png" alt="" />
							<span>{{ $store.state.curEnvStatusList[record.id.toString()] === "loading" ? "加载中" : "打开" }}</span>
						</button>
					</div>
					<a-popover placement="bottom" trigger="hover" overlayClassName="table-popover">
						<div v-if="record.bind_status" slot="content" class="popover_edit-content" @click="open_unbuind(record)">
							<div>解除常用设备</div>
						</div>
						<div slot="content" @click="$router.push(`/manage/editenv?id=${record.id}`)" class="popover_edit-content">
							<div>编辑环境</div>
						</div>
						<div slot="content" @click="authorization(record)" class="popover_edit-content">
							<div>授权成员</div>
							<!-- <a-icon type="right" /> -->
						</div>
						<div slot="content" class="popover_edit-content" @click="clearCache(record.id)" v-if="record.bind_status">
							<div>清除缓存</div>
						</div>
						<div slot="content" @click="open_delet(record)" class="popover_edit-content">
							<div>删除环境</div>
						</div>
						<div class="cell_blue mora">更多</div>
					</a-popover>
				</div>
			</a-table>
		</main>
		<template>
			<device_manage
				v-if="show_devicemanage"
				@cancel="show_devicemanage = false"
				@success="bind_device_success"
				:eventname="need_bind_env_name"
				:eventid="need_bind_env_id"
				:isshow="show_devicemanage" />
			<edit_department
				v-if="editdepartment_modalstatus"
				:modalstatus="editdepartment_modalstatus"
				:scordc="target"
				@cancel="cancel_editdepartment"
				@success="success_editdepartment" />
			<ClearCache :selectedRow="clearCache_ids" :visible.sync="clearCacheDialogVisible" @success="clearCacheSuccess" />
			<transition name="fade">
				<OpenningDialog v-if="openningDialogVisible" :env-list="multiOpenningEnvList" @close="openningDialogVisible = false" />
			</transition>
			<!-- 批量导入 -->
			<multi_add_environment :multiAddVisible.sync="multiAddVisible" @fetchList="getEnvironmentList"></multi_add_environment>
			<!-- 编辑平台弹窗 -->
			<EditPlatform :isEdit="true" @finish="isShowEditPlatform = false" @cancel="isShowEditPlatform = false" v-if="isShowEditPlatform" />
			<!-- 环境标签管理 -->
			<tag_manage v-if="show_tagmanage" @cancel="show_tagmanage = false" />
		</template>
	</div>
</template>

<script>
// api
import { environment_index } from "@/api/home";
import { environment_unbind_device, environment_delete_more, environment_get_config, batchGetEnvInfoApi } from "@/api/environment.js";
// 组件
import device_manage from "./components/manage_binddevice.vue";
import edit_department from "./components/edit_department.vue";
import ClearCache from "./components/ClearCache.vue";
import OpenningDialog from "./components/OpenningDialog.vue";
import multi_add_environment from "./components/multi_add_environment_new.vue";
import EditPlatform from "@/views/manage/environment/components/EditPlatform";
import tag_manage from "./components/tag_manage.vue";
// 其他
import { languageList } from "@/utils/languageList";
const columns = [
	{
		title: "环境名称",
		dataIndex: "env_name",
		scopedSlots: { customRender: "cell_envname" },
		show: true,
		fixed: "left",
		width: 150,
		ellipsis: true,
	},
	{
		title: "环境ID",
		dataIndex: "id",
		show: true,
		width: 100,
		ellipsis: true,
	},
	{
		title: "所属平台",
		dataIndex: "country",
		scopedSlots: { customRender: "cell_platform" },
		show: true,
		width: 150,
		ellipsis: true,
	},
	{
		title: "标签",
		dataIndex: "tag",
		show: false,
		width: 150,
		ellipsis: true,
	},
	{
		title: "企业简称",
		dataIndex: "business_short",
		show: false,
		width: 150,
		ellipsis: true,
	},
	{
		title: "设备名称",
		dataIndex: "device_name",
		show: true,
		width: 150,
		ellipsis: true,
	},
	{
		title: "设备信息",
		dataIndex: "device_ip",
		show: true,
		width: 150,
		ellipsis: true,
	},
	{
		title: "创建者",
		dataIndex: "username",
		show: false,
		width: 150,
		ellipsis: true,
	},
	{
		title: "创建时间",
		dataIndex: "created_at",
		show: false,
		width: 180,
		ellipsis: true,
	},
	{
		title: "更新时间",
		dataIndex: "updated_at",
		show: false,
		width: 180,
		ellipsis: true,
	},
	{
		title: "最后登陆时间",
		dataIndex: "last_login_time",
		show: true,
		width: 180,
		ellipsis: true,
	},
	{
		title: "最后登陆者",
		dataIndex: "last_username",
		scopedSlots: { customRender: "cell_last" },
		show: true,
		width: 150,
		ellipsis: true,
	},
	{
		dataIndex: "operation",
		width: 200,
		fixed: "right",
		slots: { title: "operaTitle" },
		scopedSlots: { customRender: "cell_operate" },
		show: true,
	},
];
export default {
	components: { device_manage, edit_department, ClearCache, OpenningDialog, multi_add_environment, EditPlatform, tag_manage },
	name: "EnvironmentList",
	data() {
		return {
			curCategory: "", // env_common, recent_open, urgent_renewal, no_auth_env, no_bind,
			nav_badge: {},
			tableData: [],
			columns,
			selectedRowKeys: [],
			table_loading: false,
			show_devicemanage: false,
			need_bind_env_name: "",
			need_bind_env_id: "",
			target: {},
			editdepartment_modalstatus: false,
			clearCache_ids: [],
			clearCacheDialogVisible: false,
			openningDialogVisible: false,
			multiOpenningEnvList: [],
			multiAddVisible: false,
			isShowEditPlatform: false,
			show_tagmanage: false,
		};
	},
	computed: {
		category() {
			const cate = {
				env_common: 0,
				recent_open: 0,
				urgent_renewal: 0,
				no_auth_env: 0,
				no_bind: 0,
			};
			if (this.curCategory) {
				cate[this.curCategory] = 1;
			}
			return cate;
		},
	},
	created() {
		this.getEnvironmentList();
	},
	watch: {
		category(newVal) {
			this.getEnvironmentList();
		},
	},
	methods: {
		async open(target) {
			this.changeEnvStatus(target.id, "loading");

			let { data } = await environment_get_config({
				env_id: target.id,
			});

			let user = localStorage.user ? JSON.parse(localStorage.member || {}) : {};
			let req = {
				...target,
				...data.data,
			};

			// 将指纹参数解构到最外层
			try {
				let { fingerprint, ...reset } = req;
				req = {
					...reset,
					...fingerprint,
					user,
				};
			} catch (error) {
				console.warn(error);
			}

			if (req.location.is_baseon_location) {
				req.location.longitude = req.lngwgs;
				req.location.latitude = req.latwgs;
			}
			//从国家语言映射表中匹配ip地址对应的语言，匹配不到则默认为英语en
			const languageItem = languageList.find(i => i.country.includes(req.country));
			//判断是否根据ip生成时区。首先调用第三方库查找，找不到则在本地列表查找，再找不到则为""
			try {
				if (!req.is_custom_timezone) {
					req.timezone = find(req.latwgs, req.lngwgs)[0];
				}
			} catch (error) {
				console.warn(error);
				const timezoneAlt = timezoneList[req.areacode];
				if (timezoneAlt) {
					req.timezone = timezoneAlt[0];
				} else {
					req.timezone = "";
				}
			}

			req.lang = languageItem ? languageItem.value : "en";
			req.device_ip = `${req.device_ip}`;
			// 是否成功从语言列表(languageList)中匹配到语言
			req.isIPLanguageOK = languageItem ? true : false;
			//插件对应的字段，req.extensions,[{id:,guid:,download_url:""}]
			if (data.code == 200) {
				let c_1 = {
					message: "/connection/proxy",
					alldata: req,
					token: localStorage.token,
				};
				let b_1 = this.$encrypt(JSON.stringify(c_1));
				this.$store.state.websocket.send(b_1);
			}
		},
		// 批量打开环境
		async batchOpen() {
			if (this.selectedRowKeys.length < 1) {
				return this.$message.error("请选择要打开的环境");
			}
			if (this.selectedRowKeys.length > 20) {
				return this.$message.error("最多批量处理20个环境");
			}

			// 选择的环境
			const selected = this.selectedRowKeys.map(item => JSON.parse(JSON.stringify(this.tableData[item])));

			// 可打开的环境 ； 过滤掉未绑定设备的环境和状态为running、loading的环境
			// const effective_env = selected.filter(item => item.bind_status);
			const effective_env = selected.filter(item => {
				return item.bind_status && this.$store.state.curEnvStatusList[item.id] == "standby";
			});

			if (!effective_env.length) {
				this.$message.error("当前选择的环境中没有可以打开的环境");
				return;
			}
			//
			const env_id = effective_env.map(item => item.id);

			// 获取环境信息
			const env_info = await batchGetEnvInfoApi({ env_id }).then(res => {
				if (res.data.code == 200) {
					return res.data.data;
				} else {
					this.$message.error(res.data.msg);
					return null;
				}
			});

			if (!env_info || !env_info.length) return;
			// 更新环境状态为加载中
			let status = JSON.parse(JSON.stringify(this.$store.state.curEnvStatusList));
			env_id.forEach(item => {
				if (status[item] != "running") {
					status[item] = "loading";
				}
				this.$store.commit("updateCurEnvStatusList", status);
			});

			// 处理环境信息，处理逻辑与单独打开环境逻辑相同；
			let user = localStorage.user ? JSON.parse(localStorage.member || {}) : {};

			const env_config = env_info.map(item => {
				// 将环境列表接口、环境信息接口下发的数据合并；
				const checked_target = effective_env.find(it => it.id == item.id);
				let env = Object.assign(checked_target, item);
				// 将指纹参数结构到最外层
				try {
					let { fingerprint, ...reset } = env;
					env = {
						...reset,
						...fingerprint,
						user,
					};
				} catch (error) {
					console.warn(error);
				}
				//
				if (env.location.is_baseon_location) {
					env.location.longitude = env.lngwgs;
					env.location.latitude = env.latwgs;
				}
				//
				//从国家语言映射表中匹配ip地址对应的语言，匹配不到则默认为英语en
				const languageItem = languageList.find(i => i.country.includes(env.country));
				//判断是否根据ip生成时区。首先调用第三方库查找，找不到则在本地列表查找，再找不到则为""
				try {
					if (!env.is_custom_timezone) {
						env.timezone = find(env.latwgs, env.lngwgs)[0];
					}
				} catch (error) {
					console.warn(error);
					const timezoneAlt = timezoneList[env.areacode];
					if (timezoneAlt) {
						env.timezone = timezoneAlt[0];
					} else {
						env.timezone = "";
					}
				}
				env.lang = languageItem ? languageItem.value : "en";
				env.device_ip = `${env.device_ip}`;
				// 是否成功从语言列表(languageList)中匹配到语言
				env.isIPLanguageOK = languageItem ? true : false;

				return env;
			});

			env_config.reverse();
			// 显示打开进度组件中，通过open_status来判断打开成功还是失败，成功=>running，失败=>standby
			this.multiOpenningEnvList = env_config.map(item => {
				return {
					...item,
					open_status: "",
				};
			});
			this.openningDialogVisible = true;

			const params = {
				message: "/connection/multiopen",
				alldata: env_config,
				token: localStorage.token,
			};
			this.$store.state.websocket.send(this.$encrypt(JSON.stringify(params)));
		},
		// 批量关闭环境
		batchClose(id) {
			const params = {
				message: "/connection/close_env",
				data: [`${id}`],
			};
			if (id) {
				params.data = [`${id}`];
			} else {
				if (this.selectedRowKeys.length < 1) {
					return this.$message.error("请选择要关闭的环境");
				}
				params.data = this.selectedRowKeys.map(item => `${this.tableData[item].id}`);
			}
			this.$store.state.websocket.send(this.$encrypt(JSON.stringify(params)));
			this.$message.success("操作成功");
		},
		// 显示窗口
		bringToFront(id) {
			const params = {
				message: "/connection/bringtofront",
				data: `${id}`,
			};
			this.$store.state.websocket.send(this.$encrypt(JSON.stringify(params)));
		},
		// 将指定环境状态修改未加载中
		changeEnvStatus(env_id, target_status) {
			let id = env_id.toString();
			let status = JSON.parse(JSON.stringify(this.$store.state.curEnvStatusList));
			status[id] = target_status;
			this.$store.commit("updateCurEnvStatusList", status);
		},
		async getEnvironmentList() {
			this.table_loading = true;
			const params = {};
			const { data } = await environment_index(params)
				.then(res => res.data)
				.finally(() => {
					this.table_loading = false;
				});
			const { list, ...reset } = data;
			this.tableData = list;
			this.nav_badge = reset;
			this.selectedRowKeys = [];
			// 获取环境的打开状态
			const ids = list.map(item => item.id).join(",");
			this.getCurEnvStatusList(ids);
		},
		//表格行选中
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys;
		},
		// 绑定设备
		go_bind(record) {
			this.show_devicemanage = true;
			this.need_bind_env_name = record.env_name;
			this.need_bind_env_id = record.id;
		},
		// 设备绑定成功
		bind_device_success() {
			this.show_devicemanage = false;
			this.getEnvironmentList();
		},
		//解绑设备弹出
		open_unbuind(record) {
			let _this = this;
			this.$confirm({
				title: "解除设备",
				content: "所选环境： " + record.env_name + " ,确定解除设备吗",
				onOk() {
					_this.go_unbinddevice(record.id);
					return false;
				},
				onCancel() {},
			});
		},
		//解绑设备
		async go_unbinddevice(id) {
			let { data } = await environment_unbind_device({
				id: id,
			});
			if (data.code == 200) {
				this.getEnvironmentList();
				this.$message.success("解绑成功");
			}
		},
		authorization(scoped) {
			this.target = scoped;
			this.editdepartment_modalstatus = true;
		},
		cancel_editdepartment() {
			this.editdepartment_modalstatus = false;
		},
		success_editdepartment() {
			this.editdepartment_modalstatus = false;
			this.getEnvironmentList();
		},
		clearCache(env_id) {
			this.clearCache_ids = [env_id];
			this.clearCacheDialogVisible = true;
		},
		// 清除缓存成功
		clearCacheSuccess() {
			this.selectedRowKeys = [];
		},
		//删除环境弹窗
		open_delet(record) {
			let _this = this;
			this.$confirm({
				title: "删除环境",
				content: "所选环境： " + record.env_name + " ,确定删除该环境吗",
				onOk() {
					let c_1 = [];
					c_1.push(record.id);
					_this.go_deletenviroment(c_1);
					return false;
				},
				onCancel() {},
			});
		},
		//批量删除环境
		async go_deletenviroment(idarr) {
			let { data } = await environment_delete_more({
				id: idarr.toString(),
			});
			if (data.code == 200) {
				this.getEnvironmentList();
				this.$message.success("删除成功");
			}
			this.tell_client_del_env(idarr);
		},
		// 删除环境上报至客户端
		tell_client_del_env(ids) {
			let env_list = ids.map(item => String(item));
			let c_1 = {
				message: "/connection/delete_env",
				env_list,
				token: localStorage.token,
			};
			let b_1 = this.$encrypt(JSON.stringify(c_1));
			this.$store.state.websocket.send(b_1);
		},
		// 获取当前分页环境列表状态
		getCurEnvStatusList(ids) {
			if (ids) {
				const params = {
					message: "/connection/env_status_msg",
					env_list: ids,
				};
				this.$store.state.websocket.send(this.$encrypt(JSON.stringify(params)));
			}
		},
	},
};
</script>
<style lang="less" scoped>
.EnvironmentList {
	margin-top: 20px;
	padding-left: 15px;
	display: flex;
	nav {
		flex: 0 0 200px;
		width: 200px;
		height: 100vh;
		position: sticky;
		top: 0;
		padding: 26px 0;
		background-color: #fff;
		.cate {
			height: 40px;
			display: flex;
			align-items: center;
			padding-left: 24px;
			margin-bottom: 8px;
			cursor: pointer;
			transition: all 0.3s;
			&:hover {
				background-color: #f0f7ff;
			}
			&.active {
				background-color: #f0f7ff;
				color: #588cff;
			}
		}
	}
	main {
		// flex: 0 0 calc(100vw - 300px);
		width: calc(100vw - 288px);
		margin-left: 10px;
		padding: 26px 16px;
		background-color: #fff;
		.header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 16px;
			::v-deep .ant-btn {
				margin-left: 10px;
			}
		}
		::v-deep .ant-table {
			.content_operate {
				display: flex;
				align-items: center;
				.cell_blue {
					padding-left: 20px;
					color: #4c84ff;
					cursor: pointer;
					line-height: 32px;

					&.mora {
						font-size: 0;
						width: 32px;
						height: 32px;
						background: url(../../../assets/img/mora-icon.png) no-repeat center center;
						background-size: 100% 100%;
						padding-left: 0;
						margin-left: 20px;
					}
				}
				.go_bind_text {
					color: #4c84ff;
					cursor: pointer;
				}
				.btns {
					display: flex;
					align-items: center;
					& > img {
						cursor: pointer;
						margin-left: 16px;
						transition: all 0.3s;
						&:hover {
							transform: scale(1.1);
						}
					}
					@keyframes loading {
						from {
							transform: rotateZ(0deg);
						}
						to {
							transform: rotateZ(-360deg);
						}
					}
					button {
						display: flex;
						align-items: center;
						border: none;
						outline: none;
						height: 30px;
						padding: 0 10px;
						border-radius: 4px;
						cursor: pointer;
						font-family: PingFang-SC;
						transition: all 0.3s;
						&[disabled="disabled"] {
							cursor: not-allowed;
							opacity: 0.6;
							img {
								animation: loading 1s linear infinite;
							}
						}
						img {
							margin-right: 4px;
						}
						span {
							color: #fff;
						}
						&.open {
							background-color: #4c84ff;
							&:hover {
								background-color: #2e65de;
							}
						}
						&.close {
							background-color: #ff4d50;
							&:hover {
								background-color: #d94144;
							}
						}
					}
				}
			}
		}
	}
}
.table-popover {
	.popover-content {
		width: 128px;
		cursor: pointer;
		height: 40px;
		line-height: 40px;
		text-align: center;
		border-radius: 3px;

		&:hover {
			background: #f7f7f7;
		}
	}

	.popover-content:hover {
		color: #4c84ff;
	}

	.popover_edit-content {
		width: 105px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		cursor: pointer;
		border-radius: 3px;
	}

	.popover_edit-content:hover {
		color: #4c84ff;
		background: #f5f5f5;
	}
}
</style>
<style>
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>

<template>
	<a-modal
		title="批量导入"
		:width="600"
		:visible="multiAddVisible"
		@cancel="
			() => {
				$emit('fetchList');
				$emit('update:multiAddVisible', false);
			}
		"
		:footer="null">
		<div class="top_tip">
			<img src="@/assets/img/tips-icon.png" alt="" class="img" />
			<div class="tip_txt">
				为减少风险，店铺绑定设备的所在时区、地区及cookies最好与之前保持一致。<br />
				一次性最多导入50个环境，环境的高级配置参数会按照默认配置自动生成。
			</div>
		</div>
		<div class="download_container">
			<p class="title">下载模板：根据模板提示完善内容</p>
			<a class="download_btn" download="批量导入环境模板.xlsx" :href="`/批量导入环境模板.xlsx?num=${Math.random()}`">下载模板(.xlsx)</a>
		</div>
		<div class="upload_container">
			<img v-show="!fileList.length" src="@/assets/img/file.png" alt="" class="img" />
			<a-upload name="file" :custom-request="customRequest" :remove="handleRemove" :multiple="false" :disabled="percent != 0" :fileList="fileList">
				<a-button> <a-icon type="upload" />上传文件</a-button>
				<p class="upload_tip">仅支持上传格式为xls或xlsx的文件</p>
			</a-upload>
			<a-progress style="padding: 10px" v-if="percent" :percent="percent" />
			<p class="result" v-if="isShowResult">
				共导入{{ envList.length }}条数据，成功<span style="color: #52c41a"><a-icon type="check" />{{ successCount }}</span
				>次，失败<span style="color: #ff4d4f"><a-icon type="warning" />{{ envList.length - successCount }}</span
				>次,<span class="exportTxt" @click="exportResult">点击导出</span>结果
			</p>
		</div>
		<div class="footer">
			<a-button @click="showRead" :disabled="percent != 0 || !envList.length">在线预览</a-button> &nbsp;&nbsp;
			<a-button @click="confirm" :disabled="percent != 0 || !envList.length || isShowResult">{{ isShowResult ? "成功导入" : "确定导入" }}</a-button>
		</div>

		<!-- 在线预览弹窗 -->
		<a-modal
			title="上传结果预览"
			:width="1200"
			:bodyStyle="{ overflow: 'auto', maxHeight: '500px' }"
			:visible="readVisible"
			@cancel="readVisible = false"
			@ok="handleOk">
			<div class="table_header">
				<span class="header_item">环境名称</span>
				<span class="header_item">平台名称</span>
				<span class="header_item">平台所属国家</span>
				<span class="header_item">绑定设备</span>
				<span class="header_item">环境标签</span>
				<span class="header_item">授权成员</span>
				<span class="header_item">企业简称</span>
				<span class="header_item">店铺账号</span>
				<span class="header_item">店铺密码</span>
				<span class="header_item">附加插件</span>
			</div>
			<a-form-model v-for="(item, index) in envList" :key="index" :model="item" :ref="'form'" :rules="rules" :layout="'inline'">
				<a-form-model-item prop="env_name">
					<a-input v-model="item.env_name" />
				</a-form-model-item>
				<a-form-model-item prop="site">
					<a-input v-model="item.site" />
				</a-form-model-item>
				<a-form-model-item
					prop="country"
					:rules="[
						{
							required: true,
							message: '国家名称为必填项',
							trigger: 'blur',
						},
						{
							validator: (rule, value, callback) => {
								if (
									platformList.some(p => {
										return p.site == item.site && p.child.some(c => c.country == value);
									})
								)
									callback();
								else callback('没有该国家对应的平台');
							},
							trigger: 'blur',
						},
					]">
					<a-input v-model="item.country" />
				</a-form-model-item>
				<a-form-model-item prop="device_name">
					<a-input v-model="item.device_name" />
				</a-form-model-item>
				<a-form-model-item prop="tagNames">
					<a-input v-model="item.tagNames" />
				</a-form-model-item>
				<a-form-model-item prop="memberNames">
					<a-input v-model="item.memberNames" />
				</a-form-model-item>
				<a-form-model-item prop="business_short">
					<a-input v-model="item.business_short" />
				</a-form-model-item>
				<a-form-model-item prop="shop_account">
					<a-input v-model="item.shop_account" />
				</a-form-model-item>
				<a-form-model-item prop="shop_password">
					<a-input v-model="item.shop_password" />
				</a-form-model-item>
				<a-form-model-item prop="add_extension">
					<a-input v-model="item.add_extension" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</a-modal>
</template>
<script>
import { importExcel, exportExcel } from "@/utils/utils.js";
import * as XLSX from "xlsx/xlsx.mjs";
import { environment_platform_list, client_v1_device, environment_tag_list, environment_create, getUserAgentApi } from "@/api/environment.js";
import { user_member_list } from "@/api/member.js";
import { anglelist } from "@/utils/environmentConfigList.js";
// 默认配置
// ❌ 已废弃
// ❗ 需要特殊处理
const environment = {
	env_name: "",
	platform_id: "",
	country_id: "",
	platform_country_id: "",
	shop_account: "",
	shop_password: "",
	tagIds: "",
	device_id: "",
	business_short: "",
	member: "",
	device_name: "",
};
const config = {
	cookie: "",
	beizhu: "",
	isuse_font: true,
	fontlist: [], // ❗
	timezone: "",
	is_custom_timezone: false,
	webrtc_set: 0,
	location: {
		location_status: 0,
		is_baseon_location: true,
		longitude: 0,
		latitude: 0,
		precision: 1000,
	},
	language: true,
	lang: "",
	langSortList: [
		{
			name: "中文（简体）",
			value: "zh-CN",
			country: "中国",
		},
		{
			name: "英语",
			value: "en",
			country: "",
		},
	],
	ac_lang: "",

	isresolution: "2",
	resolution: "1920x1080",
	canvas: true,
	webgl_graphic: 1,
	webgl_des: {
		// ❗
		status: true,
		webgl_oem: "",
		webgl_device: "",
	},
	audio_context: true,
	media_device: true,
	client_rects: true,
	speech_voices: true,
	hardware_concurrency: 4,
	memory_size: 8,
	ispc_name: false,
	pc_name: "DESKTOP-ZJCBVR",
	mac_address: "", // ❗
	do_not_track: 0,
	port_protect: {
		status: false,
		list: "",
	},
	is_enablebluetooth: false,
	batterytype: "fake",
	browser: "0",
	userAgentType: ["window"],
	linux: "", // ❌
	android: "", // ❌
	is_blockpic: false,
	is_disablevideo: false,
	is_blocksound: false,
	nofity_status: "ask",
	is_recordtaburl: false,
	is_sync_bookmarks: false,
	is_sync_indexedDB: false,
	is_sync_localstorage: false,
	is_disable_gpu: false,
	is_deltemp_blocksync: false,
	is_delcookie_blocksync: false,
	is_sync_cookies: true,
	platform: "Windows",
	kernel_version: "102",
	os_version: "",
	chrome_version: "",
	// -------------
	user_agent: "", // ❗❗❗
	ua_json: null, // ❗❗❗
	is_setssl: false,
	ssl_cipherlist: [
		"TLS_RSA_WITH_NULL_SHA",
		"TLS_RSA_WITH_3DES_EDE_CBC_SHA",
		"TLS_RSA_WITH_AES_128_CBC_SHA",
		"TLS_RSA_WITH_AES_256_CBC_SHA",
		"TLS_PSK_WITH_AES_128_CBC_SHA",
		"TLS_PSK_WITH_AES_256_CBC_SHA",
		"TLS_RSA_WITH_AES_128_GCM_SHA256",
		"TLS_RSA_WITH_AES_256_GCM_SHA384",
		"TLS_AES_128_GCM_SHA256",
		"TLS_AES_256_GCM_SHA384",
		"TLS_CHACHA20_POLY1305_SHA256",
		"TLS_ECDHE_ECDSA_WITH_AES_128_CBC_SHA",
		"TLS_ECDHE_ECDSA_WITH_AES_256_CBC_SHA",
		"TLS_ECDHE_RSA_WITH_AES_128_CBC_SHA",
		"TLS_ECDHE_RSA_WITH_AES_256_CBC_SHA",
		"TLS_ECDHE_ECDSA_WITH_AES_128_GCM_SHA256",
		"TLS_ECDHE_ECDSA_WITH_AES_256_GCM_SHA384",
		"TLS_ECDHE_RSA_WITH_AES_128_GCM_SHA256",
		"TLS_ECDHE_RSA_WITH_AES_256_GCM_SHA384",
		"TLS_ECDHE_PSK_WITH_AES_128_CBC_SHA",
		"TLS_ECDHE_PSK_WITH_AES_256_CBC_SHA",
		"TLS_ECDHE_RSA_WITH_CHACHA20_POLY1305_SHA256",
		"TLS_ECDHE_ECDSA_WITH_CHACHA20_POLY1305_SHA256",
		"TLS_ECDHE_PSK_WITH_CHACHA20_POLY1305_SHA256",
	],
	custom_url: [],
	custom_params: "",
};
export default {
	props: ["multiAddVisible"],
	data() {
		return {
			rules: {
				env_name: [
					{
						required: true,
						message: "环境名称为必填项",
						trigger: "blur",
					},
				],
				site: [
					{
						required: true,
						message: "平台名称为必填项",
						trigger: "blur",
					},
					{
						validator: (rule, value, callback) => {
							if (this.platformList.some(item => item.site == value)) callback();
							else callback("请输入快洋淘浏览器已收录的平台");
						},
						trigger: "blur",
					},
				],
				device_name: [
					{
						validator: (rule, value, callback) => {
							if (!value) return callback();
							if (this.deviceList.some(item => item.device_name == value)) callback();
							else callback("未找到此设备");
						},
						trigger: "blur",
					},
				],
				tagNames: [
					{
						validator: (rule, value, callback) => {
							if (!value) return callback();
							const allTagList = this.tagList.map(item => item.tag);
							const tags = value.split(",").filter(item => !!item);
							// 判断输入的标签名是否有效
							if (!tags.length) return callback();
							// 是否输入了正确的标签
							if (tags.every(item => allTagList.includes(item))) return callback();
							// 提示不存在的标签名
							const invalidTags = tags.filter(item => !allTagList.includes(item));
							callback(`无此标签-[${invalidTags.join(",")}]`);
						},
						trigger: "blur",
					},
				],
				memberNames: [
					{
						validator: (rule, value, callback) => {
							if (!value) return callback();

							const allUserNames = this.memberList.map(item => item.username);
							const names = value.split(",").filter(item => !!item);
							if (!names.length) return callback();
							if (names.every(item => allUserNames.includes(item))) return callback();
							const invalidTags = names.filter(item => !allUserNames.includes(item));
							callback(`无此用户-[${invalidTags.join(",")}]`);
						},
						trigger: "blur",
					},
				],
			},
			fileList: [],
			percent: 0,
			isShowResult: false,
			readVisible: false,
			envList: [],
			ua_list: [],
			//平台列表
			platformList: [],
			//设备列表
			deviceList: [],
			//标签列表
			tagList: [],
			//成员列表
			memberList: [],
		};
	},
	computed: {
		successCount() {
			const success = this.message.filter(item => item === "SUCCESS");
			return success.length;
		},
	},
	watch: {
		multiAddVisible(visible) {
			if (visible) this.getVerificationData();
			else this.handleRemove();
		},
	},
	methods: {
		// 处理数据
		async handleData() {
			const userAgentList = await this.getUserAgent(this.envList.length);
			const list = this.envList.map((item, index) => {
				const env = JSON.parse(JSON.stringify(item));
				// environment 处理逻辑
				const platform = this.platformList.find(p => p.site == item.site);
				const country = platform.child.find(c => c.country == item.country);
				// 平台、国家
				env.platform_id = platform.id;
				env.country_id = country.country_id;
				env.platform_country_id = country.id;
				delete env.country;
				delete env.site;
				// 标签
				if (env.tagNames) {
					const names = env.tagNames.split(",").filter(n => !!n);
					const ids = names.map(n => {
						const tag = this.tagList.find(t => t.tag == n);
						return tag.id;
					});
					env.tagIds = ids.join(",");
					delete env.tagNames;
				}
				// 设备
				if (env.device_name) {
					const device = this.deviceList.find(d => d.device_name == item.device_name);
					env.device_id = device.id;
				}
				// 成员
				if (env.memberNames) {
					const names = env.memberNames.split(",").filter(n => !!n);
					const ids = names.map(n => {
						const user = this.memberList.find(t => t.username == n);
						return user.id;
					});
					env.member = ids.join(",");
					delete env.memberNames;
				}
				// ----------------
				// config 处理逻辑
				const env_config = {};
				// 指纹字体；
				env_config.fontlist = this.getRandomFonts();
				// WebGL元数据
				env_config.webgl_des = this.getRandomWebGLConfig();
				// Mac地址
				env_config.mac_address = this.getRandomMacAddress();
				// UA信息
				env_config.user_agent = userAgentList[index].http_useragent;
				env_config.ua_json = userAgentList[index];
				return {
					environment: Object.assign({}, environment, env),
					config: Object.assign({}, config, env_config),
				};
			});
			return list;
		},
		// 随机生成指纹字体列表
		getRandomFonts() {
			const all_fonts = this.$store.state.localFontlist.font_datas.windows.randomfont;
			const allFontlist = all_fonts.toSorted((a, b) => Math.random() - 0.5);
			const lock_fonts = this.$store.state.localFontlist.font_datas.windows.lockfont;
			const all_length = allFontlist.length;
			const randomRange = [1, all_length];
			const random_length = Math.floor(Math.random() * (randomRange[1] - randomRange[0] + 1)) + randomRange[0];
			const fontlist = [...lock_fonts, ...allFontlist.slice(0, random_length)];
			return fontlist.join(",");
		},
		// 随机获取WebGL配置
		getRandomWebGLConfig() {
			const [webgl_oem, webgl_device] = anglelist[Math.floor(Math.random() * 43)].split("|");
			return { status: true, webgl_oem, webgl_device };
		},
		// 随机生成Mac地址
		getRandomMacAddress() {
			const arr = [
				(0x52).toString(16),
				(0x54).toString(16),
				(0x00).toString(16),
				Math.floor(Math.random() * 0xff).toString(16),
				Math.floor(Math.random() * 0xff).toString(16),
				Math.floor(Math.random() * 0xff).toString(16),
			];
			return arr.join(":");
		},
		// 获取 User Agent
		async getUserAgent(number) {
			const res = await getUserAgentApi({ number, ua_platform: "Windows" }).then(res => res.data.data);
			return res;
		},
		// 读取上传的文件
		async customRequest({ file }) {
			if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
				this.$message.error("文件格式错误，请上传xls或者xlsx格式");
				return;
			}
			// 解析出导入的环境列表
			const envList = await importExcel(file, XLSX, "environment").catch(err => {
				this.$message.error(err);
			});
			if (envList.length >= 50) {
				this.$message.error("一次最多导入50条数据");
				return;
			}
			// 读取成功
			this.envList = envList;
			this.readVisible = true;
			this.fileList = [file];
		},
		//删除文件清除文件列表
		handleRemove() {
			this.fileList = [];
			this.envList = [];
			this.isShowResult = false;
			this.percent = 0;
		},
		showRead() {
			this.readVisible = true;
		},
		//确定导入
		async confirm() {
			const validateResult = await this.validateForm();
			if (!validateResult) {
				this.$message.error("信息填写有误，请修改后重新导入");
				this.readVisible = true;
				return;
			}
			const allData = await this.handleData();

			const total = allData.length;
			let finished = 0;
			const message = [];
			// 开始上传
			for (let item of allData) {
				await environment_create(item)
					.then(res => {
						message.push(res.data.msg);
					})
					.catch(err => {
						message.push("ERROR");
					})
					.finally(() => {
						finished++;
						this.percent = Math.floor((finished / total) * 100);
					});
			}
			this.message = message;
			this.isShowResult = true;
		},
		//预览窗口确定事件
		async handleOk() {
			const validateResult = await this.validateForm();
			if (validateResult) this.readVisible = false;
		},
		async validateForm() {
			const forms = this.$refs.form;
			const promiseArr = forms.map(form => form.validate().catch(err => false));
			const res = await Promise.all(promiseArr);
			return res.every(item => item);
		},
		// 导出 导入结果
		exportResult() {
			const envList = this.envList.map((item, index) => {
				return {
					...item,
					result: this.message[index],
				};
			});
			envList.unshift({
				env_name: "环境名称",
				site: "平台名称",
				country: "平台所属国家",
				device_name: "绑定设备",
				tagNames: "环境标签",
				memberNames: "授权成员",
				business_short: "企业简称",
				shop_account: "店铺账号",
				shop_password: "店铺密码",
				result: "导入结果",
			});
			const header = [
				"env_name",
				"site",
				"country",
				"device_name",
				"tagNames",
				"memberNames",
				"business_short",
				"shop_account",
				"shop_password",
				"result",
			];

			exportExcel(XLSX, envList, header);
		},

		async getVerificationData() {
			const promiseArr = [];
			// 获取平台列表
			promiseArr.push(
				environment_platform_list({
					type: "all",
					pagesize: 999,
					page: 1,
					status: 0,
				}).then(res => res.data.data.list)
			);
			// 获取设备列表
			promiseArr.push(
				client_v1_device({
					pagesize: 999,
					page: 1,
					status: 0,
				}).then(res => res.data.data.list)
			);
			// 获取标签列表
			promiseArr.push(
				environment_tag_list({
					pagesize: 999,
					page: 1,
				}).then(res => res.data.data.list)
			);
			// 获取成员列表
			promiseArr.push(
				user_member_list({
					pagesize: 999,
					page: 1,
					status: 0,
				}).then(res => res.data.data)
			);
			const res = await Promise.all(promiseArr);
			this.platformList = Object.freeze(res[0]);
			this.deviceList = Object.freeze(res[1]);
			this.tagList = Object.freeze(res[2]);
			this.memberList = Object.freeze(res[3]);
			// 获取字体
			let c_1 = JSON.stringify({
				message: "/connection/fontlist",
			});
			let b_1 = this.$encrypt(c_1);
			this.$store.state.websocket.send(b_1);
		},
	},
};
</script>
<style scoped lang="less">
@import "@/style/mixin.less";
.ant-form.ant-form-inline {
	white-space: nowrap;
}
.top_tip {
	padding: 8px 16px;
	background: #fff9f2;
	border-radius: 3px;
	border: 1px solid #ed7b2f;
	.flex();
	.img {
		width: 20px;
	}
	.tip_txt {
		width: 490px;
		font-size: 13px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #374567;
		line-height: 20px;
	}
}
.download_container {
	margin: 20px 0 12px;
	padding-top: 20px;
	height: 102px;
	background: #f8f9fd;
	border-radius: 2px;
	border: 1px solid #dedfe2;
	text-align: center;
	.title {
		margin-bottom: 12px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #2c354b;
		line-height: 20px;
	}
	.download_btn {
		display: block;
		margin: 0 auto;
		width: 123px;
		height: 34px;
		background: #ffffff;
		border-radius: 2px;
		border: 1px solid #dedfe2;
		font-size: 14px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #2c354b;
		line-height: 34px;
		text-align: center;
	}
}
.upload_container {
	height: 200px;
	background: #f8f9fd;
	border-radius: 2px;
	border: 1px solid #dedfe2;
	.flex(center;@direction:column);
	.result {
		.exportTxt {
			color: #4c84ff;
			cursor: pointer;
		}
	}
}
::v-deep .ant-upload.ant-upload-select {
	text-align: center;
}
.footer {
	margin-top: 20px;
	.flex(flex-end);
	.confirm_btn {
		width: 90px;
		height: 30px;
		background: #f4f4f4;
		border: 1px solid #dedfe2;
	}
}
.table_header {
	width: 1950px;
	.flex();
	.header_item {
		width: 180px;
		font-weight: 700;
	}
}
</style>

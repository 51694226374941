<template>
	<a-modal v-model="isshow" :width="840" @cancel="handleCancel" @ok="ok_handle" ok-text="确认" cancel-text="取消">
		<span slot="title"
			>授权成员
			<div>请选择需要授权的成员</div>
		</span>
		<div>当前环境：{{ scordc.env_name }}</div>
		<div>所属平台：{{ scordc.country }} | {{ scordc.site }}</div>
		<div>
			<a-tabs default-active-key="1" @change="callback" :animated="false">
				<a-tab-pane key="1" tab="所有成员">
					<div class="pane-left">
						<a-input-search placeholder="搜索成员名字" style="margin-top: 20px; margin-left: 15px; width: 340px; height: 36px" @search="onStall" />

						<a-table
							:pagination="false"
							class="sec"
							:scroll="{ y: 276 }"
							:rowKey="record => record.id"
							:row-selection="{
								selectedRowKeys: selectedRowKeys,
								onChange: onSelectChange,
							}"
							:columns="columns"
							:data-source="staffAll" />
					</div>

					<div class="pane-right">
						<span class="piaop1">已选择成员</span>
						<span class="piaop2" @click="qingkong">清空已选</span>

						<a-table
							:pagination="false"
							:scroll="{ y: 367 }"
							style="width: 350px; height: 360px"
							:columns="coko"
							:data-source="dataAll"
							class="aaaaaaa">
							<a slot="action" slot-scope="text" @click="yichu(text)">移除</a>
						</a-table>
					</div>
				</a-tab-pane>
				<a-tab-pane key="2" tab="部门">
					<div class="pane-left">
						<a-input-search placeholder="搜索成员名字" style="margin-top: 20px; margin-left: 15px; width: 340px; height: 36px" @search="onSearch" />
						<div v-if="roleNum == -1">
							<div class="let-box" @click="roleFunc(0)">所有部门</div>

							<div class="let-box" @click="roleFunc(item.id)" v-for="item in bumenAll" :key="item.id">
								{{ item.title }}
								<a-icon type="right" />
							</div>
						</div>
						<div v-if="roleNum == 0" class="let-box" @click="roleFunc(-1)">所有部门</div>
						<div v-if="roleNum == 0">
							<a-table
								class="sec"
								:pagination="false"
								:scroll="{ y: 276 }"
								:rowKey="record => record.id"
								:row-selection="{
									selectedRowKeys: selectedRowKeys,
									onChange: onSelectChange,
								}"
								:columns="columns"
								:data-source="bumenList" />
						</div>
						<div v-for="item in bumenAll" :key="item.id">
							<div class="let-box" v-if="roleNum == item.id">
								<div @click="roleFunc(-1)">所有角色 / {{ item.title }}</div>
							</div>

							<a-table
								:class="roleNum == -1 ? '' : 'sec'"
								:pagination="false"
								:scroll="{ y: 276 }"
								:rowKey="record => record.id"
								:row-selection="{
									selectedRowKeys: selectedRowKeys,
									onChange: onSelectChange,
								}"
								:columns="columns"
								:data-source="bumenList" />
						</div>

						<a-table
							:pagination="false"
							class="sec"
							v-if="roleNum == -2"
							:scroll="{ y: 276 }"
							:rowKey="record => record.id"
							:row-selection="{
								selectedRowKeys: selectedRowKeys,
								onChange: onSelectChange,
							}"
							:columns="columns"
							:data-source="bumenList" />
					</div>
					<div class="pane-right">
						<span class="piaop1">已选择成员</span>
						<span class="piaop2" @click="qingkong">清空已选</span>

						<a-table
							:pagination="false"
							:scroll="{ y: 367 }"
							style="width: 350px; height: 360px"
							:columns="coko"
							:data-source="dataAll"
							class="aaaaaaa">
							<a slot="action" slot-scope="text" @click="yichu(text)">移除</a>
						</a-table>
					</div>
				</a-tab-pane>
			</a-tabs>
		</div>
	</a-modal>
</template>
<script>
import moment from "moment";
import { department, chosmember, getmember, batchenvir, batchaa } from "@/api/environment.js";
import { TreeSelect } from "ant-design-vue";
const SHOW_PARENT = TreeSelect.SHOW_PARENT;

export default {
	props: {
		modalstatus: Boolean,
		scordc: Object,
	},
	name: "TestTest",
	data() {
		return {
			isshow: false,
			auth_modalstatus: true,
			SHOW_PARENT,
			roleTitleNoe: true,
			roleNum: -1,
			bumenAll: [],
			bumenList: [],
			staffAll: [],
			columns: [
				{
					title: "username",
					dataIndex: "username",
				},
			],
			coko: [
				{
					title: "username",
					dataIndex: "username",
				},
				{
					title: "Action",
					dataIndex: "",
					key: "x",
					scopedSlots: { customRender: "action" },
				},
			],
			departmentsAll: [],
			dataAll: [],
			selectedRowKeys: [], // Check here to configure the default column
			selectedCheck: [],
			loading: false,
			pagination: {
				current: 1, //当前页数
				pageSize: 4, //每页条数
				total: 0,
				showTotal: total => `共 ${total} 条`, // 显示总数
			},
		};
	},
	watch: {
		checkedKeys() {},
	},
	mounted() {
		this.isshow = this.modalstatus;
		this.Kicn();
		this.kicon();
		this.getMem();
	},
	computed: {},
	methods: {
		yishou() {},
		qingkong() {
			this.dataAll = [];
			this.selectedRowKeys = [];
			this.selectedCheck = [];
		},
		yichu(a) {
			this.dataAll.forEach((item, index) => {
				if (item.id == a.id) {
					this.dataAll.splice(index, 1);
				}
			});
			this.selectedRowKeys.forEach((item, index) => {
				if (item == a.id) {
					this.selectedRowKeys.splice(index, 1);
				}
			});
			this.selectedCheck.forEach((item, index) => {
				if (item.id == a.id) {
					this.selectedCheck.splice(index, 1);
				}
			});
		},
		// 穿梭框 头
		onSelectChange(selectedRowKeys, checko) {
			this.selectedRowKeys = selectedRowKeys;
			this.selectedCheck = checko;
			this.dataAll = checko;
		},
		// 穿梭框 尾部
		callback(a) {},
		async onSearch(value) {
			// 查询
			if (this.roleNum == -1) {
				this.roleNum = -2;
			}
			if (value == "") {
				this.roleNum = -1;
			}
			let { data } = await chosmember({
				department_id: this.roleNum,
				real_name: value,
			});
			this.bumenList = data.data;
		},
		async onStall(value) {
			// 查询

			let { data } = await chosmember({
				department_id: 0,
				real_name: value,
			});
			this.staffAll = data.data;
		},
		async getMem() {
			let { data } = await getmember({
				id: this.scordc.id,
				env_id: this.scordc.env_config_id,
			});
			this.dataAll = data.data;
			data.data.forEach(item => {
				this.selectedRowKeys.push(item.id);
			});
			this.selectedCheck = data.data;
		},
		async roleFunc(index) {
			if (index == -1) {
				this.roleNum = index;
				this.bumenList = [];
			} else {
				this.roleNum = index;
				let { data } = await chosmember({ department_id: index });
				this.bumenList = data.data;
			}
		},
		async Kicn() {
			// 所有部门列表
			let { data } = await department({});
			this.bumenAll = data.data.list;
		},

		async kicon() {
			let { data } = await chosmember({ department_id: 0 });
			this.staffAll = data.data;
		},

		handleChange(value) {},
		moment,

		handleCancel() {
			this.isshow = false;
			this.$emit("cancel");
		},
		async ok_handle() {
			// if (this.objFrom.tax_id != "" && this.objFrom.bank != "" && this.objFrom.bank_account != "" && this.objFrom.register_address != "" && this.objFrom.register_phone != "" && this.objFrom.name != "") {

			let { data } = await batchaa({
				id: this.selectedRowKeys.length > 0 ? this.selectedRowKeys.join(",") : "",
				env_id: this.scordc.id,
			});
			if (data.code == 200) {
				this.isshow = false;
				this.$emit("success");
			}
		},
	},
};
</script>
<style scoped lang="less">
/deep/ .ant-modal-content {
	min-height: 300px;
}

/deep/ .ant-modal-title {
	height: 25px;
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 600;
	color: #2c354b;
	line-height: 25px;

	div {
		display: inline-block;
		height: 20px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		line-height: 20px;
	}
}

/deep/ .ant-modal-body {
	padding-left: 30px;
	color: #374567;
}

.line_wrap {
	display: flex;
	margin-top: 20px;

	.line_left {
		width: 140px;
		text-align: right;
		line-height: 32px;
	}

	.line_right {
		width: 430px;
		margin-left: 10px;
	}

	.text-din {
		width: 70px;
		height: 32px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #4c84ff;
		line-height: 32px;
		cursor: pointer;
	}

	.text-yuan {
		height: 32px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #ff8e43;
		line-height: 32px;
		cursor: pointer;
	}
}

/deep/ .ant-tabs-tabpane-active {
	display: flex;
}

.pane-right {
	position: relative;
	width: 350px;
	height: 369px;
	border-radius: 2px;
	opacity: 0.75;
	border: 1px solid #dedfe2;
	margin-left: 40px;

	.piaop1 {
		position: absolute;
		top: -48px;
		width: 70px;
		height: 20px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #374567;
		line-height: 20px;
		cursor: pointer;
	}

	.piaop2 {
		position: absolute;
		top: -48px;
		right: 0;
		width: 56px;
		height: 20px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #4c84ff;
		line-height: 20px;
		cursor: pointer;
	}
}

.pane-left {
	width: 370px;
	height: 369px;
	border-radius: 2px;
	opacity: 0.75;
	border: 1px solid #dedfe2;

	.let-box {
		margin-top: 15px;
		// width: 370px;
		height: 38px;
		padding-left: 15px;
		height: 20px;
		font-size: 14px;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #374567;
		line-height: 20px;
		cursor: pointer;
		position: relative;

		.anticon-right {
			margin-left: 266px;
			font-size: 12px;
			position: absolute;
			right: 18px;
		}
	}
}

/deep/ .ant-input-affix-wrapper .ant-input {
	height: 36px;
	line-height: 36px;
}

/deep/ .ant-table-thead tr th {
	height: 38px;
	background-color: #fff;
	border: none;
}

/deep/ .ant-tabs-bar {
	border: none;
}

/deep/ .ant-table-tbody tr td {
	height: 38px;
	border: none;
	background-color: #fff;
}

/deep/ .ant-table-thead {
	display: none;
}

/deep/ .pane-left .ant-table-placeholder {
	display: none;
}

/deep/ .pane-left .sec .ant-table-placeholder {
	display: block;
}

/deep/ .ant-table-placeholder {
	border: none;
}

/deep/ .ant-modal-content .ant-modal-footer {
	display: block;
}
</style>

<template>
	<div class="environment_wrap">
		<div class="content_left">
			<a-menu style="width: 210px; height: 100%" :default-selected-keys="['1']" mode="inline" :selected-keys="[current]" @click="menu_handleClick">
				<a-menu-item key="1"> 我的环境 </a-menu-item>
				<a-menu-item key="2"> 常用环境 </a-menu-item>
				<a-menu-item key="3"> 最近打开 </a-menu-item>
				<a-menu-item key="4">
					<div class="title">急需付费</div>

					<div class="title wenzihuang" v-if="urgent_renewal_count != 0">
						{{ urgent_renewal_count == 0 ? "" : urgent_renewal_count }}
					</div>
				</a-menu-item>
				<!-- <a-menu-item key="5"> 待托管账密 </a-menu-item> -->
				<a-menu-item key="6">
					<div class="title">待授权</div>

					<div class="wenzihuang" v-if="no_auth_environment != 0">
						{{ no_auth_environment == 0 ? "" : no_auth_environment }}
					</div>
				</a-menu-item>
				<a-menu-item key="7">
					<div class="title">待绑定环境</div>

					<div class="wenzihuang" v-if="no_bind_count != 0">
						{{ no_bind_count == 0 ? "" : no_bind_count }}
					</div>
				</a-menu-item>
			</a-menu>
		</div>

		<div class="content_right">
			<div class="top_line">
				<div class="top_l_left">
					<a-button type="primary" class="top_btn" @click="go_addenv" :disabled="role == 1"> 新建环境 </a-button>
					<a-button class="top_btn" @click="batchOpen"> 批量打开环境 </a-button>
					<a-button class="top_btn" @click="batchClose()"> 批量关闭环境 </a-button>
					<a-button class="top_btn" @click="multiAddVisible = true" :disabled="role == 1"> 批量导入 </a-button>
					<a-button class="top_btn" @click="isShowEditPlatform = true" :disabled="role == 1"> 自定义平台管理 </a-button>
					<a-button class="top_btn" @click="show_tagmanage = true" :disabled="role == 1"> 环境标签管理 </a-button>

					<a-popover trigger="click" v-model="batch_visibal" overlayClassName="table-popover" v-if="selectedRowKeys.length != 0">
						<div slot="content" class="popover-content" @click="batch_open('清空授权')">
							<div>清空授权</div>
						</div>

						<div slot="content" class="popover-content" @click="batch_open('清空环境标签')">
							<div>清空环境标签</div>
						</div>

						<div slot="content" class="popover-content" @click="batch_open('解绑设备')">
							<div>解绑设备</div>
						</div>

						<div slot="content" class="popover-content" @click="batch_open('设为常用环境')">
							<div>设为常用环境</div>
						</div>
						<div slot="content" class="popover-content" @click="batch_open('取消常用环境')">
							<div>取消常用环境</div>
						</div>
						<div slot="content" class="popover-content" @click="batch_open('清除缓存')">
							<div>清除缓存</div>
						</div>
						<div slot="content" class="popover-content" @click="batch_open('删除环境')">
							<div>删除环境</div>
						</div>

						<a-button class="top_btn" :disabled="selectedRowKeys.length === 0 && role == 0"> 批量操作 </a-button>
					</a-popover>

					<a-button class="top_btn" v-if="selectedRowKeys.length == 0 && role == 0" :disabled="selectedRowKeys.length === 0"> 批量操作 </a-button>
				</div>

				<div class="top_l_right">
					<!-- <a-select default-value="1" style="width: 120px" @change="event_change">
						<a-select-option value="1"> 环境管理 </a-select-option>
						<a-select-option value="2"> 环境运营 </a-select-option>
					</a-select> -->

					<a-button class="top_btn" @click="open_drawer"><i></i> 筛选 </a-button>
					<a-input-search placeholder="环境名/设备名称" class="btn_search" @search="handle_search" />
				</div>
			</div>

			<div v-if="role == 0" class="down_table">
				<a-table
					:row-selection="{
						selectedRowKeys: selectedRowKeys,
						onChange: onSelectChange,
					}"
					:loading="table_loading"
					:columns="columns"
					:data-source="table_data"
					:row-key="(r, i) => i.toString()"
					:scroll="{ x: 1200 }"
					:pagination="false"
					@change="handleTableChange">
					<div
						:title="text"
						style="max-width: 118px; text-overflow: ellipsis; overflow: hidden"
						slot="cell_envname"
						slot-scope="text"
						class="content_envname">
						{{ text }}
					</div>

					<div slot="cell_platform" slot-scope="text, record">{{ text }}{{ record.site }}</div>

					<div slot="cell_tag" slot-scope="text">
						{{ text }}
						<!-- <span v-for="item in text" :key="item.id">
              {{ item.tag }}
            </span> -->
					</div>

					<template slot="cell_device" slot-scope="text, record">
						{{ format_devicestate(text, record) }}
					</template>

					<div slot="cell_last" slot-scope="text">
						{{ text }}
					</div>

					<div slot="operaTitle" class="title_operate">
						<div class="title_operate_left">操作</div>
					</div>
					<!-- v-if="role == 0" -->
					<div slot="cell_operate" slot-scope="text, record" class="content_operate">
						<div @click="go_bind(record)" v-if="!record.bind_status" class="go_bind_text">绑定</div>
						<div class="btns" v-else>
							<!-- 打开  -->
							<button
								class="open"
								@click="go_open(record)"
								:disabled="$store.state.curEnvStatusList[record.id.toString()] === 'loading'"
								v-if="
									$store.state.curEnvStatusList[record.id.toString()] === 'standby' ||
									$store.state.curEnvStatusList[record.id.toString()] === 'loading'
								">
								<img width="18" height="18" src="@/assets/images/add_env/icon-chrome.png" alt="" />
								<span>{{ $store.state.curEnvStatusList[record.id.toString()] === "loading" ? "加载中" : "打开" }}</span>
							</button>
							<!-- 关闭  -->
							<template v-else>
								<button class="close" @click="batchClose(record.id)">
									<img width="18" height="18" src="@/assets/images/add_env/icon-chrome.png" alt="" />
									<span>关闭</span>
								</button>
								<img
									class="icon_bringToFront"
									width="16"
									height="16"
									src="@/assets/images/env/icon_computer.png"
									alt=""
									title="显示窗口"
									@click="bringToFront(record.id)" />
							</template>
						</div>
						<a-popover placement="bottom" trigger="hover" overlayClassName="table-popover">
							<!-- <div slot="content" @click="look_environment(record)" class="popover_edit-content">
                <div>查看环境</div>
              </div> -->
							<div v-if="record.bind_status" slot="content" class="popover_edit-content" @click="open_unbuind(record)">
								<div>解除常用设备</div>
							</div>
							<div slot="content" @click="go_edit(record)" class="popover_edit-content">
								<div>编辑环境</div>
							</div>
							<div slot="content" @click="authorization(record)" class="popover_edit-content">
								<div>授权成员</div>
								<!-- <a-icon type="right" /> -->
							</div>
							<div slot="content" class="popover_edit-content" @click="clearCache(record.id)" v-if="record.bind_status">
								<div>清除缓存</div>
							</div>
							<div slot="content" @click="open_delet(record)" class="popover_edit-content">
								<div>删除环境</div>
							</div>
							<div class="cell_blue mora">更多</div>
						</a-popover>
					</div>
				</a-table>
			</div>

			<div v-else class="down_table">
				<a-table
					:row-selection="{
						selectedRowKeys: selectedRowKeys,
						onChange: onSelectChange,
					}"
					:loading="table_loading"
					:columns="columns"
					:data-source="table_data"
					:row-key="(r, i) => i.toString()"
					:scroll="{ x: 2000 }"
					:pagination="false"
					@change="handleTableChange">
					<div slot="cell_envname" slot-scope="text" class="content_envname">
						{{ text }}
					</div>

					<div slot="cell_platform" slot-scope="text, record">{{ text }}{{ record.site }}</div>

					<!-- <div slot="cell_tag" slot-scope="text">
						{{ text }} -->
					<!-- <span v-for="item in text" :key="item.id">
						{{ item.tag }}
					</span> -->
					<!-- </div> -->

					<template slot="cell_device" slot-scope="text, record">
						{{ format_devicestate(text, record) }}
					</template>

					<div slot="cell_last" slot-scope="text, record">{{ text }}({{ record.business_name }})</div>

					<div slot="operaTitle" class="title_operate">
						<div class="title_operate_left">操作</div>
						<!-- <div class="title_operate_right">
                 <a-icon type="setting" />
              </div> -->
					</div>

					<div slot="cell_operate" slot-scope="text, record" class="content_operate">
						<!-- <div class="cell_leftblue">暂无权限</div> -->
						<div class="btns" v-if="record.bind_status">
							<!-- 打开  -->
							<button
								class="open"
								@click="go_open(record)"
								:disabled="$store.state.curEnvStatusList[record.id.toString()] === 'loading'"
								v-if="
									$store.state.curEnvStatusList[record.id.toString()] === 'standby' ||
									$store.state.curEnvStatusList[record.id.toString()] === 'loading'
								">
								<img width="18" height="18" src="@/assets/images/add_env/icon-chrome.png" alt="" />
								<span>{{ $store.state.curEnvStatusList[record.id.toString()] === "loading" ? "加载中" : "打开" }}</span>
							</button>
							<!-- 关闭  -->
							<template v-else>
								<button class="close" @click="batchClose(record.id)">
									<img width="18" height="18" src="@/assets/images/add_env/icon-chrome.png" alt="" />
									<span>关闭</span>
								</button>
								<img
									class="icon_bringToFront"
									width="16"
									height="16"
									src="@/assets/images/env/icon_computer.png"
									alt=""
									title="显示窗口"
									@click="bringToFront(record.id)" />
							</template>
						</div>
					</div>
				</a-table>
			</div>
			<a-pagination
				v-model="pagination.pageNum"
				show-size-changer
				:pageSizeOptions="pageSizeOptions"
				:pageSize="pagination.pageSize"
				:total="pagination.total"
				:showTotal="pagination.showTotal"
				@change="handleTableChange"
				@showSizeChange="pageSizeChange" />
		</div>

		<a-modal title="筛选" class="sai" :visible="drawer_visible" :width="520" @cancel="drawer_visible = false">
			<!-- <a-input placeholder="环境名称" v-model="standard_config.keyword" /> -->

			<div class="drawer_line">
				<div class="drawer_l_left">授权成员:</div>
				<div iv class="drawer_l_right">
					<!-- @focus="drawer_mult_select_getfocus('member')" @search="drawer_mult_select_fetchUser($event, 'member')"
          @change="drawer_multselect_handle($event, 'member_id')" -->
					<a-select v-model="standard_config.member_id" mode="multiple" placeholder="请选择授权成员" style="width: 100%" :filter-option="false">
						<a-select-option v-for="item in drawer_memberlist" :key="item.id">
							{{ item.real_name }}
						</a-select-option>
					</a-select>
				</div>
			</div>

			<div class="drawer_line">
				<div class="drawer_l_left">环境标签:</div>
				<div class="drawer_l_right">
					<!-- @focus="drawer_mult_select_getfocus('tag')"
            @search="drawer_mult_select_fetchUser($event, 'tag')" @change="drawer_multselect_handle($event, 'tag_id')" -->
					<a-select mode="multiple" v-model="standard_config.tags_id" placeholder="请选择环境标签" style="width: 100%" :filter-option="false">
						<a-select-option v-for="item in drawer_taglist" :key="item.id">
							{{ item.tag }}
						</a-select-option>
					</a-select>
				</div>
			</div>

			<div class="drawer_line">
				<div class="drawer_l_left">所属平台:</div>
				<div class="drawer_l_right">
					<!-- @change="drawer_multselect_handle($event, 'platform_id')" -->
					<a-select mode="multiple" v-model="standard_config.platform_id" placeholder="请选择所属平台" style="width: 100%">
						<a-select-option v-for="item in drawer_platformlist" :key="item.id">
							{{ item.site }}
						</a-select-option>
					</a-select>
				</div>
			</div>

			<div class="drawer_line">
				<div class="drawer_l_left">设备信息:</div>
				<div class="drawer_l_right">
					<!-- @change="drawer_multselect_handle($event, 'device_ip')" -->
					<a-select mode="multiple" v-model="standard_config.device_id" placeholder="请选择设备信息" style="width: 100%">
						<a-select-option v-for="item in drawerList" :key="item.id">
							{{ item.device_name }}
						</a-select-option>
					</a-select>
				</div>
			</div>

			<!-- <div class="drawer_line">
				<div class="drawer_l_left">设备名称:</div>
				<div class="drawer_l_right">
					<a-select mode="multiple" placeholder="" style="width: 100%" @change="drawer_multselect_handle($event, 'device_name')">
						<a-select-option v-for="item in drawer_namelist" :key="item.device_name">
							{{ item.device_name }}
						</a-select-option>
					</a-select>
				</div>
			</div>
			<div class="drawer_line">
				<div class="drawer_l_left">企业简称:</div>
				<div class="drawer_l_right">
					<a-select mode="multiple" placeholder="" style="width: 100%" @change="drawer_multselect_handle($event, 'business_short')">
						<a-select-option v-for="item in drawer_busshortlist" :key="item.business_short">
							{{ item.business_short }}
						</a-select-option>
					</a-select>
				</div>
			</div>
			<div class="drawer_line">
				<div class="drawer_l_left">创建者:</div>
				<div class="drawer_l_right">
					<a-select mode="multiple" placeholder="" style="width: 100%" @change="drawer_multselect_handle($event, 'username')">
						<a-select-option v-for="item in drawer_createrlist" :key="item.username">
							{{ item.username }}
						</a-select-option>
					</a-select>
				</div>
			</div> -->

			<div class="drawer_line">
				<div class="drawer_l_left">创建时间:</div>
				<div class="drawer_l_right">
					<!-- @change="drawer_datepecker" -->
					<a-range-picker format="YYYY-MM-DD" v-model="timetime" />
				</div>
			</div>

			<div class="drawer_line">
				<div class="drawer_l_left">网络类型:</div>
				<div class="drawer_l_right">
					<!-- @change="drawer_multselect_handle($event, 'type_id')" -->
					<a-select mode="multiple" v-model="standard_config.device_net" placeholder="请选择网络类型" style="width: 100%">
						<a-select-option v-for="item in drawer_networklist" :key="item">
							{{ item }}
						</a-select-option>
					</a-select>
				</div>
			</div>

			<!-- <div class="drawer_line">
				<div class="drawer_l_left">环境状态:</div>
				<div class="drawer_l_right">
					<a-select
						mode="multiple"
						placeholder="选择环境状态"
						style="width: 100%"
						:filter-option="false"
						@focus="drawer_mult_select_getfocus('tag')"
						@search="drawer_mult_select_fetchUser($event, 'tag')"
						@change="drawer_multselect_handle($event, 'tag_id')">
						<a-select-option :key="0"> 启用 </a-select-option>
						<a-select-option :key="1"> 禁用 </a-select-option>
					</a-select>
				</div>
			</div> -->

			<div class="btnNew">
				<a-button class="drawer_btn" type="primary" @click="reset_search"> 重置 </a-button>
				<a-button class="drawer_btn" type="primary" @click="get_tabledata"> 确定筛选 </a-button>
			</div>
		</a-modal>

		<tag_manage v-if="show_tagmanage" @cancel="cancel_tagmanage" />

		<device_manage
			v-if="show_devicemanage"
			@cancel="cancel_devicemanage"
			@success="bind_device_success"
			:eventname="need_bind_eventname"
			:eventid="need_bind_eventid"
			:isshow="show_devicemanage" />

		<edit_department
			v-if="editdepartment_modalstatus"
			:modalstatus="editdepartment_modalstatus"
			:scordc="scordc"
			@cancel="cancel_editdepartment"
			@success="success_editdepartment">
		</edit_department>
		<!-- 批量导入 -->
		<multi_add_environment :multiAddVisible.sync="multiAddVisible" @fetchList="init"></multi_add_environment>
		<!-- 编辑平台弹窗 -->
		<EditPlatform
			:isEdit="true"
			@finish="
				() => {
					isShowEditPlatform = false;
				}
			"
			@cancel="
				() => {
					isShowEditPlatform = false;
				}
			"
			v-if="isShowEditPlatform" />

		<ClearCache :selectedRow="clearCache_ids" :visible.sync="clearCacheDialogVisible" @success="clearCacheSuccess" />
		<transition name="fade">
			<OpenningDialog v-if="openningDialogVisible" :env-list="multiOpenningEnvList" @close="openningDialogVisible = false" />
		</transition>
	</div>
</template>
<script>
const { find } = require("geo-tz");
import { environment_index } from "@/api/home";
import edit_department from "./components/edit_department.vue";
import multi_add_environment from "./components/multi_add_environment_new.vue";
import EditPlatform from "@/views/manage/environment/components/EditPlatform";
import {
	environment_platform_list,
	environment_tag_list,
	environment_device_ip_list,
	environment_username_list,
	environment_delete_more,
	environment_device_name_list,
	environment_device_network_type_list,
	environment_business_short_list,
	environment_unbind_device,
	environment_clear_auth_more,
	environment_clearenvironmenttagmore,
	environment_unbinddevicemore,
	environment_commonmore,
	environment_deleteenvironmenttagmore,
	environment_info,
	getEnvironment_choose,
	environment_get_config,
	batchGetEnvInfoApi,
} from "@/api/environment.js";
import { user_member_list } from "@/api/member.js";
import tag_manage from "./components/tag_manage.vue";
import device_manage from "./components/manage_binddevice.vue";
import { typeOf } from "mathjs";
import { languageList } from "@/utils/languageList";
import timezoneList from "@/utils/areacode_timezone.json";
import ClearCache from "./components/ClearCache.vue";
import OpenningDialog from "./components/OpenningDialog.vue";
export default {
	components: {
		tag_manage,
		device_manage,
		edit_department,
		multi_add_environment,
		EditPlatform,
		ClearCache,
		OpenningDialog,
	},
	data() {
		return {
			isShowEditPlatform: false, //是否展示自定义平台管理
			isShowAddDevice: false,
			multiAddVisible: false, //批量导入
			wrap_height: null, //wrap高度

			current: "1", //选中的目录
			event_guanli: "1", //环境模式
			show_tagmanage: false, //标签管理状态
			batch_visibal: false, //批量气泡状态

			show_devicemanage: false,
			scordc: {},
			need_bind_eventname: "",
			need_bind_eventid: null,
			editdepartment_modalstatus: false, // 授权显示
			//默认配置
			standard_config: {
				status: 0,
				keywords: "",
				// platform_id: "",
				tag_id: "",
				// device_ip: "",
				device_name: "",
				business_short: "",
				// begin_created_at: "",
				// end_created_at: "",
				// urgent_renewal: null,
				// recent_open: null,
				no_tag: "all",
				// env_common: null,
				env_top: "all",
				// no_bind: null,
				// no_auth_env: null,
				// member_id: [],

				keyword: "",
				tags_id: [],
				platform_id: [],
				device_id: [],
				device_net: [],
				member_id: [],
				start_date: "",
				end_date: "",
			},
			timetime: [],
			table_data: [],
			table_loading: false,
			pagination: {
				pageNum: 1, //当前页数
				pageSize: 20, //每页条数
				total: 0,
				showTotal: total => `共 ${total} 条`, // 显示总数
			},
			pageSizeOptions: ["10", "20", "50", "100", "500"],
			no_auth_environment: "", //待授权
			no_bind_count: "", //待绑定
			urgent_renewal_count: "", //待付费

			selectedRowKeys: [], //表格 选中单元
			show_columns: [], //自定义表格头

			columns: [
				{
					title: "环境名称",
					dataIndex: "env_name",
					scopedSlots: { customRender: "cell_envname" },
					show: true,
					fixed: "left",
					width: 150,
					ellipsis: true,
				},
				{
					title: "环境ID",
					dataIndex: "id",
					show: true,
					width: 100,
					ellipsis: true,
				},
				{
					title: "所属平台",
					dataIndex: "country",
					scopedSlots: { customRender: "cell_platform" },
					show: true,
					width: 150,
					ellipsis: true,
				},
				{
					title: "标签",
					dataIndex: "tag",
					// scopedSlots: { customRender: "cell_tag" },
					show: false,
					width: 150,
					ellipsis: true,
				},
				{
					title: "企业简称",
					dataIndex: "business_short",
					show: false,
					width: 150,
					ellipsis: true,
				},
				{
					title: "设备名称",
					dataIndex: "device_name",
					show: true,
					width: 150,
					ellipsis: true,
				},
				{
					title: "设备信息",
					dataIndex: "bind_status",
					scopedSlots: { customRender: "cell_device" },
					show: true,
					width: 150,
					ellipsis: true,
				},
				{
					title: "创建者",
					dataIndex: "username",
					show: false,
					width: 150,
					ellipsis: true,
				},
				{
					title: "创建时间",
					dataIndex: "created_at",
					show: false,
					width: 180,
					ellipsis: true,
				},
				{
					title: "更新时间",
					dataIndex: "updated_at",
					show: false,
					width: 180,
					ellipsis: true,
				},
				{
					title: "最后登陆时间",
					dataIndex: "last_login_time",
					show: true,
					width: 180,
					ellipsis: true,
				},
				{
					title: "最后登陆者",
					dataIndex: "last_username",
					scopedSlots: { customRender: "cell_last" },
					show: true,
					width: 150,
					ellipsis: true,
				},
				{
					dataIndex: "operation",
					width: 200,
					fixed: "right",
					slots: { title: "operaTitle" },
					scopedSlots: { customRender: "cell_operate" },
					show: true,
				},
			],

			tag_list: [], //标签列表
			member_list: [], //成员列表
			platform_list: [], //平台列表

			device_iplist: [], //设备信息列表
			device_namelist: [], //设备名称列表
			network_typelist: [], //网络类型列表
			busine_shortlist: [], //企业简称列表
			creater_namelist: [], //创建者列表

			drawer_visible: false, //抽屉状态
			drawer_taglist: [],
			drawer_memberlist: [],
			drawer_platformlist: [],

			drawer_iplist: [],
			drawerList: [],
			drawer_namelist: [],
			drawer_networklist: [],
			drawer_busshortlist: [],
			drawer_createrlist: [],

			websocketObj: null,
			websocket_port: 42536,
			check_record: null, //选中的表格行

			role: null,
			clearCacheDialogVisible: false,
			clearCache_ids: [],
			// 批量打开弹窗
			openningDialogVisible: false,
			// 批量打开的环境
			multiOpenningEnvList: [],
		};
	},
	mounted() {
		this.get_platformlist();
		this.get_taglist();
		this.get_member_data();

		this.init();

		this.set_wrap_height();

		this.getFil();
		this.isYuan();
	},
	computed: {
		envExCheck() {
			return this.$store.state.envExCheck;
		},
	},
	watch: {
		// async envExCheck(newVal) {
		// 	console.log(newVal);
		// 	//恢复打开按钮的可用状态
		// 	if (newVal.open_success == 1) {
		// 		this.$message.success("已成功打开环境，请点击桌面底部快洋淘浏览器的新窗口前往店铺平台");
		// 		this.changeEnvStatus(this.check_record.id, "standby");
		// 	} else {
		// 		this.$message.error("打开失败，请重试");
		// 		this.changeEnvStatus(this.check_record.id, "standby");
		// 	}
		// },
	},
	methods: {
		pageSizeChange(current, size) {
			this.pagination.pageSize = size;
			this.handleTableChange(current, size);
		},
		isYuan() {
			let aa = localStorage.getItem("member");
			var arr = aa.split(",");

			arr.pop();
			arr.shift();
			var obj = {};
			for (var i = 0; i < arr.length; i++) {
				var arr2 = arr[i].split(":");
				var key = arr2[0];
				var value = arr2[1];
				key = key.replace('"', "").replace('"', "");

				//动态添加属性值
				obj[key] = value;
			}
			this.role = obj.user_role;
		},
		//授权人员弹窗
		// 授权成员
		authorization(scoped) {
			this.scordc = scoped;
			this.editdepartment_modalstatus = true;
		},
		// open_editdepartment_pop() {
		//   this.editdepartment_modalstatus = true
		// },
		cancel_editdepartment() {
			this.editdepartment_modalstatus = false;
		},
		success_editdepartment() {
			this.editdepartment_modalstatus = false;
			this.init();
		},
		async getFil() {
			let { data } = await getEnvironment_choose();
			this.drawer_memberlist = data.data.member;
			this.drawer_taglist = data.data.tags;
			this.drawer_platformlist = data.data.platform;
			this.drawer_iplist = data.data.device_ip;
			this.drawerList = data.data.device;
			this.drawer_networklist = data.data.device_net;
		},
		//高度绑定为页面高度
		set_wrap_height() {
			this.wrap_height = document.body.clientHeight - 82;
		},
		//打开环境
		go_open(record) {
			this.changeEnvStatus(record.id, "loading");
			this.check_record = record;
			this.websocket_open_client();
		},

		async websocket_open_client() {
			let { data } = await environment_get_config({
				// id: this.check_record.id, // 不知道为啥要传id，接口直接收了env_id，先注释
				env_id: this.check_record.id,
			});
			let user = localStorage.user ? JSON.parse(localStorage.member || {}) : {};
			let req = {
				...this.check_record,
				...data.data,
				// transport_ip: "101.89.44.90:40000",
			};

			// 将指纹参数解构到最外层
			try {
				let { fingerprint, ...reset } = req;
				req = {
					...reset,
					...fingerprint,
					user,
				};
			} catch (error) {}

			if (req.location.is_baseon_location) {
				req.location.longitude = req.lngwgs;
				req.location.latitude = req.latwgs;
			}
			//从国家语言映射表中匹配ip地址对应的语言，匹配不到则默认为英语en
			const languageItem = languageList.find(i => i.country.includes(req.country));
			//判断是否根据ip生成时区。首先调用第三方库查找，找不到则在本地列表查找，再找不到则为""
			try {
				if (!req.is_custom_timezone) {
					req.timezone = find(req.latwgs, req.lngwgs)[0];
				}
			} catch (error) {
				const timezoneAlt = timezoneList[req.areacode];
				if (timezoneAlt) {
					req.timezone = timezoneAlt[0];
				} else {
					req.timezone = "";
				}
			}

			req.lang = languageItem ? languageItem.value : "en";
			req.device_ip = `${req.device_ip}`;
			// 是否成功从语言列表(languageList)中匹配到语言
			req.isIPLanguageOK = languageItem ? true : false;
			//插件对应的字段，req.extensions,[{id:,guid:,download_url:""}]
			if (data.code == 200) {
				let c_1 = {
					message: "/connection/proxy",
					// serverIp: this.check_record.device_ip,
					// browser_id: this.check_record.id,
					// env_name: this.check_record.env_name,
					alldata: req,
					token: localStorage.token,
				};
				let b_1 = this.$encrypt(JSON.stringify(c_1));
				this.$store.state.websocket.send(b_1);
			}
		},

		//解绑设备弹出
		open_unbuind(record) {
			let that = this;
			this.$confirm({
				title: "解除设备",
				content: "所选环境： " + record.env_name + " ,确定解除设备吗",
				onOk() {
					that.go_unbinddevice(record.id);
					return false;
				},
				onCancel() {},
			});
		},
		//解绑设备
		async go_unbinddevice(id) {
			let { data } = await environment_unbind_device({
				id: id,
			});
			if (data.code == 200) {
				this.init();
				this.$message.success("解绑成功");
			}
		},

		//删除环境弹窗
		open_delet(record) {
			let that = this;
			this.$confirm({
				title: "删除环境",
				content: "所选环境： " + record.env_name + " ,确定删除该环境吗",
				onOk() {
					let c_1 = [];
					c_1.push(record.id);
					that.go_deletenviroment(c_1);
					return false;
				},
				onCancel() {},
			});
		},
		//批量删除环境
		async go_deletenviroment(idarr) {
			let { data } = await environment_delete_more({
				id: idarr.toString(),
			});
			if (data.code == 200) {
				this.get_tabledata();
				this.$message.success("删除成功");
			}
			this.tell_client_del_env(idarr);
		},

		//selectedRowKeys
		//批量操作确认弹窗
		batch_open(title) {
			this.batch_visibal = false;
			let c_1 = [];
			this.selectedRowKeys.forEach(item => {
				c_1.push(this.table_data[item].id);
			});
			switch (title) {
				case "清除缓存":
					this.clearCache_ids = this.selectedRowKeys.map(item => this.table_data[item].id);
					this.clearCacheDialogVisible = true;
					break;

				default:
					let that = this;
					this.$confirm({
						title: title,
						content: "环境:" + c_1.length + "个,确定进行该批量操作吗",
						onOk() {
							that.go_clear_auth(c_1, title);
							return false;
						},
						onCancel() {},
					});
					break;
			}
		},
		//批量操作jaax
		async go_clear_auth(idarr, type) {
			if (type == "清空授权") {
				let { data } = await environment_clear_auth_more({
					id: idarr.toString(),
				});
				if (data.code == 200) {
					this.get_tabledata();
					this.$message.success("清空授权 操作成功");
				}
			}
			if (type == "清空环境标签") {
				let { data } = await environment_clearenvironmenttagmore({
					id: idarr.toString(),
				});
				if (data.code == 200) {
					this.get_tabledata();
					this.$message.success("清空环境标签 操作成功");
				}
			}

			if (type == "解绑设备") {
				let { data } = await environment_unbinddevicemore({
					id: idarr.toString(),
				});
				if (data.code == 200) {
					this.get_tabledata();
					this.$message.success("解绑设备 操作成功");
				}
			}

			if (type == "设为常用环境") {
				let { data } = await environment_commonmore({
					dev_common: 1,
					id: idarr.toString(),
				});
				if (data.code == 200) {
					this.get_tabledata();
					this.$message.success("设为常用环境 操作成功");
				}
			}

			if (type == "取消常用环境") {
				let { data } = await environment_commonmore({
					dev_common: 0,
					id: idarr.toString(),
				});
				if (data.code == 200) {
					this.get_tabledata();
					this.$message.success("取消常用环境 操作成功");
				}
			}

			if (type == "删除环境标签") {
				let { data } = await environment_deleteenvironmenttagmore({
					id: idarr.toString(),
				});
				if (data.code == 200) {
					this.get_tabledata();
					this.$message.success("删除环境标签 操作成功");
				}
			}

			if (type == "删除环境") {
				let { data } = await environment_delete_more({
					id: idarr.toString(),
				});
				if (data.code == 200) {
					this.get_tabledata();
					this.$message.success("删除环境 操作成功");
				}
				this.tell_client_del_env(idarr);
			}
		},

		//打开drawer
		open_drawer() {
			this.get_device_iplist();
			this.get_device_namelist();
			this.get_busine_shortlist();
			this.get_creater_namelist();
			this.get_network_typelist();

			this.drawer_visible = true;
		},
		//取消drawer
		drawer_onClose() {
			this.drawer_visible = false;
		},
		//drawer 多选 选中回调
		drawer_multselect_handle(params, type) {
			this.standard_config[type] = params.toString();
		},
		//drawer 多选  获得焦点
		drawer_mult_select_getfocus(type) {
			// if (type == "tag") {
			//   this.drawer_taglist = this.tag_list;
			// }
			// if (type == "member") {
			//   this.drawer_platformlist = this.platform_list;
			// }
		},
		//drawer 多选  搜索
		async drawer_mult_select_fetchUser(value, type) {
			// if (type == "tag") {
			//   let { data } = await environment_tag_list({
			//     tag: value,
			//   });
			//   if (data.code == 200) {
			//     this.drawer_taglist = data.data.list;
			//   }
			// }
			// if (type == "member") {
			//   let { data } = await user_member_list({
			//     keywords: value,
			//   });
			//   if (data.code == 200) {
			//     this.drawer_memberlist = data.data.list;
			//   }
			// }
		},
		//drawer 日期回调
		drawer_datepecker(date) {
			// this.standard_config.begin_created_at = date[0].format("YYYY-MM-DD");
			// this.standard_config.end_created_at = date[1].format("YYYY-MM-DD");
		},

		//获得成员列表
		async get_member_data(keywords) {
			// let { data } = await user_member_list({
			//   keywords: keywords,
			// });
			// if (data.code == 200) {
			//   this.member_list = data.data.list;
			//   this.drawer_memberlist = this.member_list;
			// }
		},

		//取消标签管理
		cancel_tagmanage() {
			this.show_tagmanage = false;
		},
		//取消添加设备
		cancel_devicemanage() {
			this.show_devicemanage = false;
		},
		bind_device_success() {
			this.show_devicemanage = false;
			this.init();
		},
		//平台列表
		async get_platformlist() {
			// let { data } = await environment_platform_list({
			//   type: "all",
			//   pagesize: 100,
			//   page: 1,
			// });
			// if (data.code == 200) {
			//   this.platform_list = data.data.list;
			//   this.drawer_platformlist = this.platform_list;
			// }
		},
		//标签列表
		async get_taglist() {
			// let { data } = await environment_tag_list({});
			// if (data.code == 200) {
			//   this.tag_list = data.data.list;
			//   this.drawer_taglist = this.tag_list;
			// }
		},

		//设备信息列表
		async get_device_iplist() {
			// let { data } = await environment_device_ip_list({});
			// if (data.code == 200) {
			//   this.device_iplist = data.data.list;
			//   this.drawer_iplist = this.device_iplist;
			// }
		},
		//设备名称列表
		async get_device_namelist() {
			let { data } = await environment_device_name_list({});
			if (data.code == 200) {
				this.device_namelist = data.data.list;
				this.drawer_namelist = this.device_namelist;
			}
		},
		//企业简称列表
		async get_busine_shortlist() {
			let { data } = await environment_business_short_list({});
			if (data.code == 200) {
				this.busine_shortlist = data.data.list;
				this.drawer_busshortlist = this.busine_shortlist;
			}
		},
		//创建者列表
		async get_creater_namelist() {
			let { data } = await environment_username_list({});
			if (data.code == 200) {
				this.creater_namelist = data.data.list;
				this.drawer_createrlist = this.creater_namelist;
			}
		},
		//网络类型列表
		async get_network_typelist() {
			// let { data } = await environment_device_network_type_list({});
			// if (data.code == 200) {
			//   this.network_typelist = data.data.list;
			//   this.drawer_networklist = this.network_typelist;
			// }
		},

		async menu_handleClick(e) {
			this.current = e.key;

			Object.assign(this.$data, this.$options.data());
			this.current = e.key;

			this.standard_config = Object.assign(this.standard_config, {
				no_auth_env: e.key == 6 ? 1 : 0, //未授权环境
				urgent_renewal: e.key == 4 ? 1 : 0, //急需付费
				no_bind: e.key == 7 ? 1 : 0, //未绑定设备
				recent_open: e.key == 3 ? 1 : 0, //最近打开
				env_common: e.key == 2 ? 1 : 0, //常用环境
			});
			this.isYuan();
			if (this.timetime.length) {
				this.standard_config.start_date = this.timetime[0].format("YYYY-MM-DD");
				this.standard_config.end_date = this.timetime[1].format("YYYY-MM-DD");
			}
			this.table_loading = true;
			this.selectedRowKeys = [];
			let { data } = await environment_index({
				...this.standard_config,
				pagesize: this.pagination.pageSize,
				page: this.pagination.pageNum,
			});
			this.table_loading = false;
			if (data.code == 200) {
				this.pagination.total = data.data.total;
				this.no_auth_environment = data.data.no_auth_environment;
				this.no_bind_count = data.data.no_bind_count;
				this.urgent_renewal_count = data.data.urgent_renewal_count;

				this.table_data = data.data.list;
				this.getCurEnvStatusList();
			}
		},

		async init() {
			this.table_loading = true;
			let { data } = await environment_index({
				status: 0,
				pagesize: this.pagination.pageSize,
				page: this.pagination.pageNum,
				...this.standard_config,
			});
			this.table_loading = false;
			if (data.code == 200) {
				this.pagination.total = data.data.total;

				this.no_auth_environment = data.data.no_auth_environment;
				this.no_bind_count = data.data.no_bind_count;
				this.urgent_renewal_count = data.data.urgent_renewal_count;
				this.table_data = data.data.list;
				this.getCurEnvStatusList();
			}
		},
		//表格行选中
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys;
		},

		//搜索回调
		handle_search(keywords) {
			this.standard_config.keyword = keywords;
			this.pagination.pageNum = 1;
			this.get_tabledata();
		},

		//表格 切换页码
		handleTableChange(pagination, pagesize) {
			this.pagination.pageNum = pagination;
			this.get_tabledata();
		},
		reset_search() {
			this.timetime = [];
			this.standard_config = {
				status: 0,
				keywords: "",
				// platform_id: "",
				tag_id: "",
				// device_ip: "",
				device_name: "",
				business_short: "",
				// begin_created_at: "",
				// end_created_at: "",
				urgent_renewal: 0,
				recent_open: 0,
				no_tag: "all",
				env_common: 0,
				env_top: "all",
				no_bind: 0,
				no_auth_env: 0,
				// member_id: [],

				keyword: "",
				tags_id: [],
				platform_id: [],
				device_id: [],
				device_net: [],
				member_id: [],
				start_date: "",
				end_date: "",
			};
		},
		//获得表格数据
		async get_tabledata() {
			this.isYuan();
			if (this.timetime.length) {
				this.standard_config.start_date = this.timetime[0].format("YYYY-MM-DD");
				this.standard_config.end_date = this.timetime[1].format("YYYY-MM-DD");
			}
			this.table_loading = true;
			this.selectedRowKeys = [];
			let { data } = await environment_index({
				...this.standard_config,
				pagesize: this.pagination.pageSize,
				page: this.pagination.pageNum,
			});
			this.table_loading = false;
			if (data.code == 200) {
				this.pagination.total = data.data.total;
				this.no_auth_environment = data.data.no_auth_environment;
				this.no_bind_count = data.data.no_bind_count;
				this.urgent_renewal_count = data.data.urgent_renewal_count;
				this.table_data = data.data.list;
				this.getCurEnvStatusList();
			}
		},
		event_change(value) {
			this.event_guanli = value;
		},
		go_addenv() {
			this.$router.push({ name: "manage_addenv" });
		},

		format_devicestate(state, record) {
			if (!state) {
				return "未绑定";
			} else if (record.describe["user_ip"]) {
				return record.describe["user_ip"];
			} else {
				return record.device_ip + " " + record.device_area_title + " " + record.device_package_title;
			}
		},

		go_bind(record) {
			this.show_devicemanage = true;
			this.need_bind_eventname = record.env_name;
			this.need_bind_eventid = record.id;
		},
		go_edit(record) {
			this.$router.push({ path: "editenv", query: { id: record.id } });
		},
		// 查看环境
		look_environment() {},
		// 删除环境上报至客户端
		tell_client_del_env(ids) {
			let env_list = ids.map(item => String(item));
			let c_1 = {
				message: "/connection/delete_env",
				env_list,
				token: localStorage.token,
			};
			let b_1 = this.$encrypt(JSON.stringify(c_1));
			this.$store.state.websocket.send(b_1);
		},
		// 获取当前分页环境列表状态
		getCurEnvStatusList() {
			if (this.table_data.length > 0) {
				let env_list = this.table_data.map(item => item.id).join(",");
				const params = {
					message: "/connection/env_status_msg",
					env_list,
				};
				this.$store.state.websocket.send(this.$encrypt(JSON.stringify(params)));
			}
		},
		// 将指定环境状态修改未加载中
		changeEnvStatus(env_id, target_status) {
			let id = env_id.toString();
			let status = JSON.parse(JSON.stringify(this.$store.state.curEnvStatusList));
			status[id] = target_status;
			this.$store.commit("updateCurEnvStatusList", status);
		},
		clearCache(env_id) {
			this.clearCache_ids = [env_id];
			this.clearCacheDialogVisible = true;
		},
		// 清除缓存成功
		clearCacheSuccess() {
			this.selectedRowKeys = [];
		},
		// 批量打开环境
		async batchOpen() {
			if (this.selectedRowKeys.length < 1) {
				return this.$message.error("请选择要打开的环境");
			}
			if (this.selectedRowKeys.length > 20) {
				return this.$message.error("最多批量处理20个环境");
			}

			// 选择的环境
			const selected = this.selectedRowKeys.map(item => JSON.parse(JSON.stringify(this.table_data[item])));

			// 可打开的环境 ； 过滤掉未绑定设备的环境和状态为running、loading的环境
			// const effective_env = selected.filter(item => item.bind_status);
			const effective_env = selected.filter(item => {
				return item.bind_status && this.$store.state.curEnvStatusList[item.id] == "standby";
			});

			if (!effective_env.length) {
				this.$message.error("当前选择的环境中没有可以打开的环境");
				return;
			}
			//
			const env_id = effective_env.map(item => item.id);

			// 获取环境信息
			const env_info = await batchGetEnvInfoApi({ env_id }).then(res => {
				if (res.data.code == 200) {
					return res.data.data;
				} else {
					this.$message.error(res.data.msg);
					return null;
				}
			});

			if (!env_info || !env_info.length) return;
			// 更新环境状态为加载中
			let status = JSON.parse(JSON.stringify(this.$store.state.curEnvStatusList));
			env_id.forEach(item => {
				if (status[item] != "running") {
					status[item] = "loading";
				}
				this.$store.commit("updateCurEnvStatusList", status);
			});

			// 处理环境信息，处理逻辑与单独打开环境逻辑相同；
			let user = localStorage.user ? JSON.parse(localStorage.member || {}) : {};

			const env_config = env_info.map(item => {
				// 将环境列表接口、环境信息接口下发的数据合并；
				const checked_target = effective_env.find(it => it.id == item.id);
				let env = Object.assign(checked_target, item);
				// 将指纹参数结构到最外层
				try {
					let { fingerprint, ...reset } = env;
					env = {
						...reset,
						...fingerprint,
						user,
					};
				} catch (error) {
					console.warn(error);
				}
				//
				if (env.location.is_baseon_location) {
					env.location.longitude = env.lngwgs;
					env.location.latitude = env.latwgs;
				}
				//
				//从国家语言映射表中匹配ip地址对应的语言，匹配不到则默认为英语en
				const languageItem = languageList.find(i => i.country.includes(env.country));
				//判断是否根据ip生成时区。首先调用第三方库查找，找不到则在本地列表查找，再找不到则为""
				try {
					if (!env.is_custom_timezone) {
						env.timezone = find(env.latwgs, env.lngwgs)[0];
					}
				} catch (error) {
					const timezoneAlt = timezoneList[env.areacode];
					if (timezoneAlt) {
						env.timezone = timezoneAlt[0];
					} else {
						env.timezone = "";
					}
				}
				env.lang = languageItem ? languageItem.value : "en";
				env.device_ip = `${env.device_ip}`;
				// 是否成功从语言列表(languageList)中匹配到语言
				env.isIPLanguageOK = languageItem ? true : false;

				return env;
			});

			env_config.reverse();
			// 显示打开进度组件中，通过open_status来判断打开成功还是失败，成功=>running，失败=>standby
			this.multiOpenningEnvList = env_config.map(item => {
				return {
					...item,
					open_status: "",
				};
			});
			this.openningDialogVisible = true;

			const params = {
				message: "/connection/multiopen",
				alldata: env_config,
				token: localStorage.token,
			};
			this.$store.state.websocket.send(this.$encrypt(JSON.stringify(params)));
		},
		// 批量关闭环境
		batchClose(id) {
			const params = {
				message: "/connection/close_env",
				data: [`${id}`],
			};
			if (id) {
				params.data = [`${id}`];
			} else {
				if (this.selectedRowKeys.length < 1) {
					return this.$message.error("请选择要关闭的环境");
				}
				params.data = this.selectedRowKeys.map(item => `${this.table_data[item].id}`);
			}
			this.$store.state.websocket.send(this.$encrypt(JSON.stringify(params)));
			this.$message.success("操作成功");
		},
		// 显示窗口
		bringToFront(id) {
			const params = {
				message: "/connection/bringtofront",
				data: `${id}`,
			};
			this.$store.state.websocket.send(this.$encrypt(JSON.stringify(params)));
		},
	},
};
</script>

<style scoped lang="less">
/deep/ .ant-modal-header {
	border: 0;
}

/deep/ .ant-calendar-picker {
	width: 100%;
}

::v-deep {
	.ant-modal {
		.ant-modal-body {
			padding-top: 0 !important;
		}
	}

	.ant-popover-inner-content {
		padding: 8px;
	}
}

// .sai {
//   .ant-modal-footer {
//     background: #ff0000;
//   }
// }

.table-popover {
	.popover-content {
		width: 128px;
		cursor: pointer;
		height: 40px;
		line-height: 40px;
		text-align: center;
		border-radius: 3px;

		&:hover {
			background: #f7f7f7;
		}
	}

	.popover-content:hover {
		color: #4c84ff;
	}

	.popover_edit-content {
		width: 105px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		cursor: pointer;
		border-radius: 3px;
	}

	.popover_edit-content:hover {
		color: #4c84ff;
		background: #f5f5f5;
	}
}

.environment_wrap {
	min-width: 1845px;
	padding-left: 15px;
	margin: 0 auto;
	min-height: 600px;
	margin-top: 20px;

	//border: 1px solid green;
	display: flex;

	.content_left {
		padding-top: 38px;
		min-width: 210px;
		background-color: white;
	}

	.content_right {
		background-color: white;
		flex: 1;
		margin-right: 20px;
		margin-left: 10px;

		.top_line {
			display: flex;
			justify-content: space-between;
			padding-top: 25px;

			.top_l_left {
				display: flex;

				.top_btn {
					margin-left: 15px;
					border-radius: 0;
				}

				.btn_search {
					margin-left: 15px;
					width: 450px;
					height: 30px;
				}
			}

			.top_l_right {
				display: flex;

				.btn_search {
					width: 450px;
					height: 30px;
					margin-right: 15px;
					border-radius: 0;

					/deep/ input {
						border-radius: 0;
					}
				}

				.top_btn {
					margin-left: 15px;
					margin-right: 20px;
					border-radius: 0;
					float: left;
					display: flex;
					align-items: center;
					justify-content: center;

					i {
						width: 16px;
						height: 16px;
						background: url(~@/assets/img/filter-icon.png) no-repeat center center;
						background-size: 100% 100%;
						display: inline-block;
						margin-right: 4px;
					}
				}
			}
		}

		.down_table {
			margin-top: 14px;
			width: 100%;
			padding: 0 13px;

			.title_operate {
				display: flex;
				width: 200px;
				justify-content: space-between;
			}

			.content_operate {
				display: flex;
				align-items: center;
				.cell_blue {
					padding-left: 20px;
					color: #4c84ff;
					cursor: pointer;
					line-height: 32px;

					&.mora {
						font-size: 0;
						width: 32px;
						height: 32px;
						background: url(../../../assets/img/mora-icon.png) no-repeat center center;
						background-size: 100% 100%;
						padding-left: 0;
						margin-left: 20px;
					}
				}
				.go_bind_text {
					color: #4c84ff;
					cursor: pointer;
				}
				.btns {
					display: flex;
					align-items: center;
					& > img {
						cursor: pointer;
						margin-left: 16px;
						transition: all 0.3s;
						&:hover {
							transform: scale(1.1);
						}
					}
					@keyframes loading {
						from {
							transform: rotateZ(0deg);
						}
						to {
							transform: rotateZ(-360deg);
						}
					}
					button {
						display: flex;
						align-items: center;
						border: none;
						outline: none;
						height: 30px;
						padding: 0 10px;
						border-radius: 4px;
						cursor: pointer;
						font-family: PingFang-SC;
						transition: all 0.3s;
						&[disabled="disabled"] {
							cursor: not-allowed;
							opacity: 0.6;
							img {
								animation: loading 1s linear infinite;
							}
						}
						img {
							margin-right: 4px;
						}
						span {
							color: #fff;
						}
						&.open {
							background-color: #4c84ff;
							&:hover {
								background-color: #2e65de;
							}
						}
						&.close {
							background-color: #ff4d50;
							&:hover {
								background-color: #d94144;
							}
						}
					}
				}
			}
		}
	}
}
.title {
	font-size: 14px;
	font-family: Source Han Sans CN;
	font-weight: 400;
	color: #374567;
	line-height: 46px;
	display: inline-block;
}
.wenzihuang {
	padding: 0 10px;
	display: inline-block;
	height: 16px;
	border-radius: 8px;
	border: 1px solid #ffbe00;
	font-size: 12px !important;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #ffbe00 !important;
	line-height: 15px !important;
	margin-top: 15px;
	margin-left: 11px;
}
.drawer_line {
	display: flex;
	height: 32px;
	margin-top: 18px;

	.drawer_l_left {
		width: 75px;
		flex: none;
		line-height: 32px;
	}

	.drawer_l_right {
		flex: 1;
	}
}

.btnNew {
	// position: absolute;
	// bottom: 20px;
	// left: 0;
	margin-top: 20px;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	display: flex;
	justify-content: space-between;

	.drawer_btn {
		margin-right: 10px !important;
		background: #f5f5f5;
		border: none;
		font-size: 14px;
		font-weight: 400;
		color: #374567;
		line-height: 22px;
	}
}

.chrome-icon {
	display: inline-block;
	background: url(../../../assets/img/chrome-icon.png) no-repeat center center;
	background-size: 100% 100%;
	width: 16px;
	height: 16px;
	margin-right: 4px;
}
.ant-pagination {
	padding: 30px 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
::v-deep .ant-table-wrapper {
	width: 82vw;
}
</style>
<style>
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>

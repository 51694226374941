export const languageList=[
  {
    name: "中文（简体）",
    value: "zh-CN",
    country:"中国"
  },
  {
    name: "中文（繁体）",
    value: "zh-TW",
    country:"香港 台湾"
  },
  {
    name: "英语",
    value: "en",
    country:""
  },
  {
    name: "英语（澳大利亚）",
    value: "en-AU",
    country:"澳大利亚"
  },
  {
    name: "英语（加拿大）",
    value: "en-CA",
    country:"加拿大"
  },
  {
    name: "英语（美国）",
    value: "en-US",
    country:"美国"
  },
  {
    name: "英语（南非）",
    value: "en-ZA",
    country:"南非"
  },
  {
    name: "英语（新西兰）",
    value: "en-NZ",
    country:"新西兰"
  },
  {
    name: "英语（印度）",
    value: "en-IN",
    country:"印度"
  },
  {
    name: "英语（英国）",
    value: "en-GB",
    country:"英国"
  },
  {
    name: "阿拉伯语",
    value: "ar",
    country:"-阿尔及利亚\n" +
        "ar-BH\t阿拉伯语 -巴林\n" +
        "ar-EG\t阿拉伯语 -埃及\n" +
        "ar-IQ\t阿拉伯语 -伊拉克\n" +
        "ar-JO\t阿拉伯语 -约旦\n" +
        "ar-KW\t阿拉伯语 -科威特\n" +
        "ar-LB\t阿拉伯语 -黎巴嫩\n" +
        "ar-LY\t阿拉伯语 -利比亚\n" +
        "ar-MA\t阿拉伯语 -摩洛哥\n" +
        "ar-OM\t阿拉伯语 -阿曼\n" +
        "ar-QA\t阿拉伯语 -卡塔尔\n" +
        "ar-SA\t阿拉伯语 - 沙特阿拉伯\n" +
        "ar-SY\t阿拉伯语 -叙利亚共和国\n" +
        "ar-TN\t阿拉伯语 -北非的共和国\n" +
        "ar-AE\t阿拉伯语 - 阿拉伯联合酋长国\n" +
        "ar-YE\t阿拉伯语 -也门"
  },
  {
    name: "阿姆哈拉语",
    value: "am",
    country:" 埃塞俄比亚"
  },
  {
    name: "爱尔兰语",
    value: "ga",
    country:"爱尔兰"
  },
  {
    name: "爱沙尼亚语",
    value: "et",
    country:"特立尼达"
  },
  {
    name: "保加利亚语",
    value: "bg",
    country:"保加利亚语"
  },
  {
    name: "波兰语",
    value: "pl",
    country:"尼诺斯克 波兰"
  },
  {
    name: "波斯语",
    value: "fa",
    country:" 伊朗"
  },
  {
    name: "丹麦语",
    value: "da",
    country:"丹麦"
  },
  {
    name: "德语",
    value: "de",
    country:"德国"
  },
  {
    name: "德语（奥地利）",
    value: "de-AT",
    country:"奥地利"
  },
  {
    name: "德语（德国）",
    value: "de-DE",
    country:"德国"
  },
  {
    name: "德语（列支敦士登）",
    value: "de-LI",
    country:"列支敦士登"
  },
  {
    name: "德语（瑞士）",
    value: "de-CH",
    country:"瑞士"
  },
  {
    name: "俄语",
    value: "ru",
    country:"哈萨克斯坦 俄国"
  },
  {
    name: "法语",
    value: "fr",
    country:"比利时"
  },
  {
    name: "法语（法国）",
    value: "fr-FR",
    country:"法国"
  },{
    name: "法语（加拿大）",
    value: "fr-CA",
    country:"加拿大"
  },
  {
    name: "法语（瑞士）",
    value: "fr-CH",
    country:"瑞士"
  }, {
    name: "菲律宾语",
    value: "fil",
    country:"菲律宾"
  }, {
    name: "芬兰语",
    value: "fi",
    country:"芬兰"
  }, {
    name: "古吉拉特语",
    value: "gu",
    country:""
  }, {
    name: "韩语",
    value: "ko",
    country:"韩国 朝鲜"
  }, {
    name: "荷兰语",
    value: "nl",
    country:"贺兰 阿鲁巴"
  }, {
    name: "加泰罗尼亚语",
    value: "ca",
    country:"安道尔"
  },
  {
    name: "捷克语",
    value: "cs",
    country:"捷克"
  },{
    name: "卡纳达语",
    value: "kn",
    country:""
  },{
    name: "克罗地亚语",
    value: "hr",
    country:"塞尔维亚"
  },{
    name: "拉脱维亚语",
    value: "lv",
    country:"拉脱维亚"
  },{
    name: "立陶宛语",
    value: "lt",
    country:"立陶宛"
  },
  {
    name: "罗马尼亚语",
    value: "ro",
    country:"罗马尼亚"
  },{
    name: "马拉地语",
    value: "mr",
    country:""
  },{
    name: "马拉雅拉姆语",
    value: "ml",
    country:""
  },{
    name: "马来语",
    value: "ms",
    country:""
  },{
    name: "孟加拉语",
    value: "bn",
    country:""
  },{
    name: "挪威尼诺斯克语",
    value: "nn",
    country:""
  },
  {
    name: "葡萄牙语",
    value: "pt",
    country:""
  },
  {
    name: "葡萄牙语（巴西）",
    value: "pt-BR",
    country:"巴西"
  },
  {
    name: "葡萄牙语（葡萄牙）",
    value: "pt-PT",
    country:"葡萄牙"
  },
  {
    name: "日语",
    value: "ja",
    country:"日本"
  },
  {
    name: "瑞典语",
    value: "sv",
    country:""
  },
  {
    name: "塞尔维亚语",
    value: "sr",
    country:""
  },
  {
    name: "泰语",
    value: "th",
    country:""
  },
  {
    name: "土耳其语",
    value: "tr",
    country:""
  },
  {
    name: "乌克兰语",
    value: "uk",
    country:""
  },
  {
    name: "西班牙语（西班牙）",
    value: "es",
    country:"西班牙"
  },
  {
    name: "西班牙语（阿根廷）",
    value: "es-AR",
    country:"阿根廷"
  },
  {
    name: "西班牙语（哥伦比亚）",
    value: "es-CO",
    country:"哥伦比亚"
  },
  {
    name: "西班牙语（哥斯达黎加）",
    value: "es-CR",
    country:"哥斯达黎加"
  },
  {
    name: "西班牙语（洪都拉斯）",
    value: "es-HN",
    country:"洪都拉斯"
  },
  {
    name: "西班牙语（拉丁美洲）",
    value: "es-419",
    country:"拉丁美洲"
  },{
    name: "意大利语",
    value: "it",
    country:"意大利 瑞士"
  },
  {
    name: "印度尼西亚语",
    value: "id",
    country:"印度尼西亚"
  },
  {
    name: "越南语",
    value: "vi",
    country:"越南"
  },
]
<template>
	<div class="tag_wrap">
		<a-modal visible title="环境标签管理" :width="840" @cancel="handleCancel" :footer="null">
			<div class="tip">
				<p>新建及修改标签，提供设备可选择标签内容</p>
				<strong>总共{{ check_list.length }}个标签</strong>
			</div>
			<div class="top">
				<a-input class="top_input" v-model="new_tag" placeholder="输入标签名" />
				<a-button type="primary" class="top_btn" @click="create_tag"> 确认添加 </a-button>
				<a-button class="top_btn" :disabled="checked.length === 0" @click="delete_tag"> 删除标签 </a-button>
			</div>

			<div class="content">
				<div class="tag_cell" v-for="item in check_list" :key="item.id">
					<input class="tag_input" type="checkbox" :id="item.id" :value="item.id" v-model="checked" />
					<label :for="item.id" class="tag_c_txt">{{ item.tag }}</label>

					<a-popover v-model="item.visible" trigger="click">
						<div slot="content" class="popo_content">
							<div class="pop_txt">重命名:</div>
							<a-input class="pop_input" :maxLength="30" v-model="item.tag" />
							<a-button type="primary" class="pop_btn" @click="update_tag(item)"> 确定 </a-button>
						</div>

						<div class="tag_c_edit">编辑</div>
					</a-popover>
				</div>
			</div>

			<!-- <a-button type="primary" class="btn_delet" @click="delete_tag">
        删除标签
      </a-button> -->
		</a-modal>
	</div>
</template>
<script>
import { environment_tag_list, update_environment_tag, create_environment_tag, environment_deleteenvironmenttagmore } from "@/api/environment.js";
export default {
	data() {
		return {
			new_tag: "",
			check_list: [],
			checked: [],
		};
	},
	mounted() {
		this.get_taglist();
	},
	methods: {
		test() {},
		async get_taglist() {
			let { data } = await environment_tag_list({});
			if (data.code == 200) {
				data.data.list.forEach(item => {
					item.visible = false;
				});
				this.check_list = data.data.list;
			}
		},
		handleCancel() {
			this.$emit("cancel");
		},
		async create_tag() {
			if (!this.new_tag) {
				this.$message.error("请输入标签名");
				return;
			}
			let { data } = await create_environment_tag({
				tag: this.new_tag,
			});
			if (data.code == 200) {
				this.$message.success("添加成功");
				this.new_tag = "";
				this.get_taglist();
			}
		},
		async update_tag(item) {
			if (item.tag.length >= 30) {
				this.$message.error("内容太长");
				return false;
			}
			let { data } = await update_environment_tag({
				tag: item.tag,
				id: item.id,
			});
			if (data.code == 200) {
				this.$message.success("修改成功");
				this.get_taglist();
			}
		},
		async delete_tag() {
			if (this.checked.length == 0) {
				return;
			}
			let { data } = await environment_deleteenvironmenttagmore({
				id: this.checked.toString(),
			});
			if (data.code == 200) {
				this.$message.success("删除成功");
				this.get_taglist();
			}
		},
	},
};
</script>
<style scoped lang="less">
/deep/ .ant-modal-footer {
	display: none;
}

/deep/ .ant-modal-content {
	height: 530px;
	padding: 2px 10px;
}

/deep/ .ant-modal-header {
	border: 0;
}

/deep/ .ant-modal-body {
	padding-top: 0;
}

.tip {
	display: flex;
	justify-content: space-between;
	margin-bottom: 28px;

	p {
		font-size: 13px;
		font-weight: 400;
		color: #374567;
		margin-bottom: 0;
	}

	strong {
		font-weight: normal;
		font-size: 13px;
		font-weight: 400;
		color: #ababab;
	}
}

.top {
	display: flex;
	//height: 50px;
	line-height: 32px;
	padding-bottom: 5px;
	//border-bottom: 1px solid #e9e9e9;

	.top_txt {
		width: 80px;
	}

	.top_input {
		width: 200px;
	}

	.top_btn {
		margin-left: 10px;
	}

	.right_btn {
		margin-left: auto;
	}
}

.content {
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	width: 100%;
	height: 300px;
	margin-top: 10px;
	overflow-y: auto;
	// border: 1px solid #e9e9e9;

	.tag_cell {
		display: flex;
		margin-right: 16px;
		margin-top: 12px;
		min-width: 164px;
		height: 35px;
		line-height: 20px;
		cursor: pointer;
		padding: 10px;
		border: 1px solid #dedfe2;
		font-size: 13px;
		font-weight: 500;
		color: #374567;
		line-height: 18px;

		.tag_input {
			padding-top: 5px;
		}

		.tag_c_txt {
			margin-left: 5px;
			margin-top: -3px;
			font-size: 15px;
		}

		.tag_c_edit {
			margin-left: 20px;
			margin-top: -3px;
			font-size: 13px;
			cursor: pointer;
		}

		.tag_c_edit:hover {
			color: #4c84ff;
		}
	}
}

.popo_content {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	width: 280px;

	.pop_txt {
		width: 46px;
		flex: none;
	}

	.pop_input {
		width: 100px;
		margin-left: 10px;
	}

	.pop_btn {
		margin-left: 10px;
	}
}

.btn_delet {
	margin-top: 10px;
	float: right;
}
</style>

<template>
	<a-modal :visible="visible" @ok="ok" @cancel="cancel" :destroyOnClose="true" :maskClosable="false" title="清除缓存" :width="750" :confirm-loading="loading">
		<a-form-model :model="config" layout="horizontal" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" ref="clearCacheForm" :rules="rules">
			<a-form-model-item label="环境ID">
				<a-tag v-for="(item, index) in selectedRow" :key="index">{{ item }}</a-tag>
			</a-form-model-item>
			<a-form-model-item label="位置" prop="position">
				<a-checkbox-group v-model="config.position" prop="position">
					<a-checkbox value="delete_local" name="position">
						<div>
							<b>本地存储</b>
							<p>清除后，本地浏览器中网站的登录状态等会全部失效</p>
						</div>
					</a-checkbox>
					<a-checkbox value="delete_online" name="position">
						<div>
							<b>云端</b>
							<p>清除后，跨设备无法同步相关数据</p>
						</div>
					</a-checkbox>
				</a-checkbox-group>
			</a-form-model-item>
			<a-form-model-item label="缓存类型" prop="type">
				<a-checkbox-group v-model="config.type">
					<a-checkbox value="delete_localstorage" name="type"> Local Storage </a-checkbox>
					<a-checkbox value="delete_indexedDB" name="type"> IndexedDB </a-checkbox>
					<a-checkbox value="delete_cookie" name="type"> Cookie </a-checkbox>
				</a-checkbox-group>
			</a-form-model-item>
		</a-form-model>
	</a-modal>
</template>

<script>
export default {
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		selectedRow: {
			type: Array,
			default: () => [],
		},
	},
	components: {},
	name: "ClearCache",
	data() {
		return {
			config: {
				type: [],
				position: [],
			},
			rules: {
				type: [
					{
						required: true,
						message: "请选择要清除的缓存类型",
						trigger: "change",
					},
				],
				position: [
					{
						required: true,
						message: "请选择清除位置",
						trigger: "change",
					},
				],
			},
			loading: false,
			closeTipDialogVisible: false,
		};
	},
	methods: {
		ok() {
			this.$refs.clearCacheForm.validate(result => {
				if (result) {
					let running = this.selectedRow.filter(item => this.$store.state.curEnvStatusList[item] === "running");
					if (running.length) {
						this.closeTipDialogVisible = true;
						this.$error({
							title: "提示",
							content: `您当前选择的环境中 [${this.selectedRow.join(",")}] 正在运行中，请先关闭环境后再进行操作。`,
							okText: "确定",
						});
						return;
					}
					this.loading = true;
					let config = {
						delete_cookie: this.config.type.includes("delete_cookie"),
						delete_localstorage: this.config.type.includes("delete_localstorage"),
						delete_indexedDB: this.config.type.includes("delete_indexedDB"),
						delete_local: this.config.position.includes("delete_local"),
						delete_online: this.config.position.includes("delete_online"),
					};
					let list = this.selectedRow.map(item => {
						return {
							env_id: `${item}`,
							...config,
						};
					});
					let params = {
						message: "/connection/delete_cache",
						delete_env_list: list,
					};
					this.$store.state.websocket.send(this.$encrypt(JSON.stringify(params)));
					setTimeout(() => {
						this.loading = false;
						this.cancel();
						this.$message.success("清除成功");
						this.$emit("success");
					}, 1000);
				}
			});
		},
		cancel() {
			this.$emit("update:visible", false);
		},
	},
};
</script>
<style lang="less" scoped>
::v-deep .ant-modal {
	.ant-modal-content {
		.ant-modal-header {
			border-bottom: 1px solid #e8e8e8;
			.ant-modal-title {
				font-weight: bold;
				font-size: 18px;
			}
		}
		.ant-modal-body {
			padding: 24px !important;
		}
	}
}
::v-deep .ant-form {
	.ant-row {
		.ant-col {
			& + .ant-col {
				margin-left: 16px;
			}
		}
		&:nth-child(2) {
			.ant-checkbox-group {
				.ant-checkbox-wrapper {
					display: block;
					margin: 0;
					display: flex;
					align-items: center;
					b {
						font-weight: normal;
					}
					p {
						color: #999;
					}
					& + .ant-checkbox-wrapper {
						margin-top: 10px;
					}
				}
			}
		}
	}
}
</style>
